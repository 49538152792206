import React from 'react'
import CandidateHotlistTable from '../components/candidateTable/CandidateHotlistTable'
import "../style/dashboard.css"
import BenchsalesModal from '../components/popup/BenchsalesModal'


const Dashboard = () => {
  return (
    <div>
      <CandidateHotlistTable/>
    </div>
  )
}

export default Dashboard
