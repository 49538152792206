import { async } from "q";
import Http from "./http";

export  const hotlistTableAllData = async(data:any)=>{
    return await Http.post('candidate/all-in-one-candidate-search',data,false)
}


export const getBenchSalesById = async(id:any)=>{
    return await Http.get(`benchsales/get-benchsales/${id}`,false)
} 

export const hotlistTableAllDataDelete = async(deleteId:any)=>{
    return await Http.delete(`candidate/multiple-delete-candidate`,deleteId,true)
}
 
export const candidateGetNote = async (id:any) => {
    return await Http.get(`candidate/get-candidate-note/${id}`,false)
}

export const candidateNoteById = async (id:any) =>{
    return await Http.get(`candidate/get-candidate-note/${id}`,true)
}

export const addCandidateNote = async (data:any) => {
    return await Http.post(`candidate/add-candidate-note`,data,true)
}