import Http from "../../services/http";
import { useSelector } from "react-redux";
import exportFromJSON from "export-from-json";
import { FaFileExport } from "react-icons/fa";
import { Modal } from "antd";
import { useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { ReactComponent as ExportIcon } from "../../Image/Export.svg";
import { useDispatch } from "react-redux";
import { setRefresh } from "../../redux/candidateTableSlice";
import exportColumns from "./ExportColumns";

const ExportCandidate = ({ allTableData }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const objData: any = useSelector(
    (state: any) => state?.candidateTable?.filterData
  );
  const objDataId: any = useSelector((state: any) => state?.candidateTable);

  const handleExport = async () => {
    if (allTableData.length > 0) {
      setIsModalOpen(true);
      if (objDataId?.id?.length > 0) {
        await Http.post(
          "candidate/all-in-one-candidate-search",
          { ...objData, isExport: true, id: objDataId?.id },
          false
        )
          .then(async (res: any) => {
            await setTimeout(() => {
              const date = new Date();
              const fileName = `Hotlist_Data_${date.getDate()}- ${
                date.getMonth() + 1
              }-${date.getFullYear()}/time_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
              const unCleanData = res?.data?.data;
              const data = unCleanData?.map((ele:any) => {
                const newSingleData:any = {};
              
                Object.keys(ele).forEach((key) => {

                  for (const field of exportColumns || []) {
                    if (key === field.accessor) {
                      const findFieldName = field.Header;
                      newSingleData[findFieldName] = ele[key];
                      break; // Exit the loop since the key was found
                    }
                  }
                });
              
                return newSingleData;
              });


              const exportType = exportFromJSON.types.csv;
              exportFromJSON({ data, fileName, exportType });
              if (res.success) {
                setIsModalOpen(false);
                dispatch(setRefresh());
              }
            }, 3000);
          })
          .catch((err: any) => {
            setIsModalOpen(false);
          });
      } else {
        await Http.post(
          "candidate/all-in-one-candidate-search",
          { ...objData, isExport: true },
          false
        )
          .then(async (res: any) => {
            await setTimeout(() => {
              const date = new Date();
              const fileName = `Hotlist_Data_${date.getDate()}- ${
                date.getMonth() + 1
              }-${date.getFullYear()}/time_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
              const unCleanData = res?.data?.data;
              const data = unCleanData?.map((ele:any) => {
                const newSingleData:any = {};
              
                Object.keys(ele).forEach((key) => {

                  for (const field of exportColumns || []) {
                    if (key === field.accessor) {
                      const findFieldName = field.Header;
                      newSingleData[findFieldName] = ele[key];
                      break; // Exit the loop since the key was found
                    }
                  }
                });
              
                return newSingleData;
              });              
              const exportType = exportFromJSON.types.csv;
              exportFromJSON({ data, fileName, exportType });
              if (res.success) {
                setIsModalOpen(false);
              }
            }, 3000);

            

          //  const data = res?.data?.data?.map((ele:any)=>{
          //     let newSingleData:any = {}

          //     Object.keys(ele).forEach((key:any)=>{
          //       exportColumns.map((e:any)=>{

          //         if(key === e?.accessor){
          //           return newSingleData = {...newSingleData ,[e.Header]: ele[key]}
          //         }

          //         // if(key === e?.accessor){
          //         //   return newSingleData[e.Header] = ele[key]
          //         // }

                  

          //       })

          //     })
          //     return newSingleData

          //   })


          })
          .catch((err: any) => {
            setIsModalOpen(false);
          });
      }
    }
  };

  return (
    <>
      <Modal
        width={330}
        open={isModalOpen}
        onOk={() => {}}
        onCancel={() => {}}
        closable={false}
        footer={null}
      >
        <div className="rocket-box">
          <Player
            autoplay
            loop
            src="https://assets2.lottiefiles.com/packages/lf20_mmvrvrif.json"
            style={{ height: "310px", width: "310px" }}
          >
            <div className="lottie-text-box">
              <span>Please wait</span>
            </div>
          </Player>
        </div>
      </Modal>
      <div
        className="candidate-hotlist-table-in-reset-export"
        onClick={handleExport}
      >
        {/* <FaFileExport
          fontSize={23}
          style={
            allTableData?.length > 0
              ? { cursor: "pointer", color:"#265F9D" }
              : { cursor: "not-allowed", color: "darkgray" }
          }
        />
        <span
          style={
            allTableData?.length > 0
              ? { fontSize: 10, cursor: "pointer", color:"#265F9D" }
              : { fontSize: 10, cursor: "not-allowed", color: "darkgray" }
          }
        >
          <b>Export</b>
        </span> */}

        <ExportIcon className="exportIcon" />
      </div>
    </>
  );
};

export default ExportCandidate;
