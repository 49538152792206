const hotlistHeaderData = [{
    Header: 'Sr.No',
    accessor: 'id',
    isFiltered: true,
    isSorted:true

},
{
    Header: "Candidate's Name",
    accessor: 'fullName',
    isFiltered: true,
    isSorted:true
},
{
    Header: 'Title',
    accessor: 'title',
    isFiltered: true,
    isSorted:true

},
{
    Header: 'Experience',
    accessor: 'experience',
    isFiltered: true,
    isSorted:true

},
{
    Header: 'Visa Type',
    accessor: 'visaType',
    isFiltered: true,
    isSorted:true

},
{
    Header: 'City',
    accessor: 'city',
    isFiltered: true,
    isSorted:true

},
{
    Header: 'State',
    accessor: 'state',
    isFiltered: true,
    isSorted:true

},
{
    Header: 'Relocation',
    accessor: 'relocation',
    isFiltered: true,
    isSorted:true

},
{
    Header: 'Availability',
    accessor: 'availability',
    isFiltered: true,
    isSorted:true

},

{
    Header: 'Notes',
    accessor: 'note',
},
{
    Header: 'CreatedBy',
    accessor: 'createdBy',
    isFiltered: true,
    isSorted:true

},
{
    Header: 'UpdatedBy',
    accessor: 'updatedBy',
    isFiltered: true,
    isSorted:true

},
{
    Header: 'CreatedAt',
    accessor: 'createdAt',
    isFiltered: true,
    isSorted:true

},
{
    Header: 'UpdatedAt',
    accessor: 'updatedAt',
    isFiltered: true,
    isSorted:true

},



]

export default hotlistHeaderData