import React, { useEffect, useMemo, useState } from "react";
import { useColumnOrder, useRowSelect, useTable } from "react-table";
import "../../style/CandidateHotlistTable.css";
import { FaSortAmountDown, FaSortAmountUpAlt } from "react-icons/fa";
import { BiError } from "react-icons/bi";
import { forwardRef, useImperativeHandle } from "react";
import hotlistHeaderData from "./candidateColumns";
import { hotlistTableAllData } from "../../services/HotlistTableData";
import { FiEdit3 } from "react-icons/fi";
import BenchsalesModal from "../popup/BenchsalesModal";
import { Space, Spin, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setDeleteId } from "../../redux/candidateTableSlice";
import CandidateTableHead from "./CandidateTableHead";
import CandidateTablePagination from "./CandidateTablePagination";
import moment from "moment";
import FilterBox from "./FilterBox";
import NotePopModal from "../popup/NotePopModal";
import { useLocation, useNavigate } from "react-router-dom";
import { setBenchSalesId } from "../../redux/hotlistForm";

const CandidateHotlistTable = () => {
  const [allTableData, setAllTableData] = useState([]);
  const [paginationData, setPaginationData]: any = useState([]);
  const [perPagePagination, setPerPagePagination] = useState("10");
  const [currentPage, setCurrentPage]: any = useState(1);
  const [searchAnyData, setSearchAnyData] = useState("");
  const [tableDataLoader, setTableDataLoader] = useState(false);

  const selected = useSelector((state: any) => state?.candidateTable?.refresh);
  const { user } = useSelector((state: any) => state.user);

  const [sortOrder, setSortOrder] = useState(false);
  const [sortColumn, setSortColumn] = useState("");
  const [newNoteAdd, setNewNoteAdd] = useState(false);
  const location: any = useLocation();
  const objData: any = useSelector(
    (state: any) => state?.candidateTable?.filterData
  );
  const formRedux: any = useSelector((state: any) => state?.form);

  const navigate: any = useNavigate();

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }: any, ref) => {
      const defaultRef: any = React.useRef();
      const resolvedRef: any = ref || defaultRef;
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);
      return (
        <>
          <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            className="input-type-checkbox"
          />
        </>
      );
    }
  );

  // const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBenchSalesId(""));
  }, []);

  useEffect(() => {
    if (location.pathname === "/hotlist-form") {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRedux?.bechSalesId]);

  useEffect(() => {
    const dataObj = {
      ...objData,
      sortType: sortOrder ? "ASC" : "DESC",
      sortColumn: sortColumn == "" ? "createdAt" : sortColumn,
      // page: searchAnyData.length > 0 ? 1 : currentPage,
      page: currentPage,
      perPage: perPagePagination,
      searchBy: searchAnyData,
    };

    const dataObjForm = {
      ...objData,
      sortType: sortOrder ? "ASC" : "DESC",
      sortColumn: sortColumn == "" ? "createdAt" : sortColumn,
      // page: searchAnyData.length > 0 ? 1 : currentPage,
      page: currentPage,
      perPage: perPagePagination,
      searchBy: searchAnyData,
      specialBody: {
        benchSaleIds: [formRedux?.bechSalesId],
        domains: [],
      },
    };

    if (location.pathname === "/hotlist-form") {
      if (searchAnyData.length > 0) {
        setTableDataLoader(true);
        const getData: any = setTimeout(() => {
          hotlistTableAllData(dataObjForm).then((res: any) => {
            setAllTableData(res?.data?.data);
            setPaginationData(res?.data?.pagination);
            setTableDataLoader(false);
          });
        }, 800);
        return () => clearTimeout(getData);
      } else {
        setTableDataLoader(true);
        hotlistTableAllData(dataObjForm).then((res: any) => {
          setAllTableData(res?.data?.data);
          setPaginationData(res?.data?.pagination);
          setTableDataLoader(false);
        });
      }
    } else {
      if (searchAnyData.length > 0) {
        setTableDataLoader(true);
        const getData: any = setTimeout(() => {
          hotlistTableAllData(dataObj).then((res: any) => {
            setAllTableData(res?.data?.data);
            setPaginationData(res?.data?.pagination);
            setTableDataLoader(false);
          });
        }, 800);
        return () => clearTimeout(getData);
      } else {
        setTableDataLoader(true);
        hotlistTableAllData(dataObj).then((res: any) => {
          setAllTableData(res?.data?.data);
          setPaginationData(res?.data?.pagination);
          setTableDataLoader(false);
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    perPagePagination,
    currentPage,
    searchAnyData,
    selected,
    sortOrder,
    sortColumn,
    objData,
    formRedux?.bechSalesId,
    formRedux?.formRefresh,
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    setColumnOrder,
    visibleColumns,
    rows,
    allColumns,
    prepareRow,
    selectedFlatRows,
  }: any = useTable(
    { columns: hotlistHeaderData, data: allTableData },
    useRowSelect,
    useColumnOrder,
    (hooks) => {
      hooks.visibleColumns.push((columns): any => {
        if (user?.role === "0") {
          return [
            {
              id: "Selection",
              Header: ({ getToggleAllRowsSelectedProps }: any) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              Cell: ({ row }: any) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ];
        } else {
          return [...columns];
        }
      });
    }
  );

  const tbodyData = rows.slice();
  const dispatch = useDispatch();

  useEffect(() => {
    let Iddata: any = [];
    selectedFlatRows.map((ele: any) => {
      return Iddata.push(Number(ele?.original?.id));
    });
    dispatch(setDeleteId(Iddata));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlatRows]);

  let candidateTableHiddenColumns = JSON.parse(
    localStorage.getItem("candidateTableHiddenColumns") || "[]"
  );

  if (location.pathname === "/hotlist-form") candidateTableHiddenColumns = [];

  const table = (allTableData: any) => {
    return (
      <>
        <CandidateTableHead
          setColumnOrder={setColumnOrder}
          visibleColumns={visibleColumns}
          allColumns={allColumns}
          allTableData={allTableData}
          setSearchAnyData={setSearchAnyData}
          setCurrentPage={setCurrentPage}
          searchAnyData={searchAnyData}
        />
        <div className="table-box">
          <table className="candidate-hotlist-table-data" {...getTableProps()}>
            <thead className="candidate-hotlist-table-thead-data">
              {headerGroups?.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup?.headers?.map((column: any) => {
                    if (column.id === "Selection") {
                      return (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      );
                    } else {
                      return (
                        <>
                          {!candidateTableHiddenColumns?.includes(
                            column.id
                          ) && (
                            <th {...column.getHeaderProps()}>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  minWidth:
                                    column.id === "id" ? "10px" : "170px",
                                  gap: "5px",
                                }}
                              >
                                {column.render("Header")}

                                {column.isFiltered && (
                                  <FilterBox
                                    column={column}
                                    data={allTableData}
                                    tbodyData={tbodyData}
                                    setCurrentPage={setCurrentPage}
                                  />
                                )}

                                {column.isSorted &&
                                  (sortOrder && sortColumn === column.id ? (
                                    <FaSortAmountDown
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setSortOrder(false);
                                        setSortColumn(column.id);
                                      }}
                                    />
                                  ) : (
                                    <FaSortAmountUpAlt
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setSortOrder(true);
                                        setSortColumn(column.id);
                                      }}
                                    />
                                  ))}
                              </span>
                            </th>
                          )}
                        </>
                      );
                    }
                  })}
                </tr>
              ))}
            </thead>

            <tbody
              {...getTableBodyProps()}
              className="candidate-hotlist-table-tbody-data"
            >
              {tableDataLoader ? (
                <>
                  <span
                    style={{ position: "absolute", top: "150px", left: "18%" }}
                  >
                    <h1>
                      {location.pathname === "/hotlist-form"
                        ? "Please Select a Bechsales"
                        : null}
                    </h1>
                  </span>
                  <Space size="middle">
                    <Spin size="large" />
                  </Space>
                </>
              ) : (
                <>
                  {tbodyData.length > 0 ? (
                    <>
                      {tbodyData?.map((row: any) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row?.cells?.map((cell: any) => {
                              if (
                                candidateTableHiddenColumns?.includes(
                                  cell?.column?.id
                                )
                              )
                                return <></>;
                              if (cell?.column?.id === "fullName") {
                                return (
                                  <td
                                    style={{
                                      maxWidth: "100%",
                                      overflow: "Hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {location.pathname === "/hotlist-form" ? (
                                      <Tooltip
                                        title={cell?.row?.original?.fullName}
                                        placement="bottomLeft"
                                      >
                                        <span
                                          style={{
                                            maxWidth: "170px",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "block",
                                          }}
                                        >
                                          {cell?.row?.original?.fullName === ""
                                            ? "Name not available"
                                            : cell?.row?.original?.fullName}
                                        </span>
                                      </Tooltip>
                                    ) : (
                                      <span className="edit-but-in-hot-list">
                                        <u
                                          style={{
                                            color: "#006295",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <BenchsalesModal
                                            name={cell?.row?.original}
                                            setCurrentPage={setCurrentPage}
                                          />{" "}
                                        </u>
                                        <FiEdit3
                                          onClick={() => {
                                            navigate("/hotlist-form", {
                                              state: cell?.row?.original,
                                            });
                                          }}
                                          style={{
                                            color: "#A4A5A5",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </span>
                                    )}
                                  </td>
                                );
                              }

                              if (cell?.column?.id === "title") {
                                return (
                                  // <Tooltip open={isPopUpOpen} title={cell?.row?.original?.title} placement="bottomLeft" ></Tooltip>
                                  <td>
                                    <Tooltip
                                      title={cell?.row?.original?.title}
                                      placement="bottomLeft"
                                    >
                                      {cell?.row?.original?.title}
                                    </Tooltip>
                                  </td>
                                );
                              }

                              if (cell?.column?.id === "createdAt") {
                                return (
                                  <td>
                                    {moment(
                                      cell?.row?.original?.createdAt
                                    ).format("MMMM Do YYYY, h:mm A")}
                                  </td>
                                );
                              }
                              if (cell?.column?.id === "updatedAt") {
                                return (
                                  <td>
                                    {moment(
                                      cell?.row?.original?.updatedAt
                                    ).format("MMMM Do YYYY, h:mm A")}
                                  </td>
                                );
                              }

                              if (cell?.column?.id === "note") {
                                return (
                                  <td>
                                    <NotePopModal
                                      dataById={cell?.row?.original}
                                      setNewNoteAdd={setNewNoteAdd}
                                      newNoteAdd={newNoteAdd}
                                    />
                                  </td>
                                );
                              } else {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {cell.render("Cell").props.cell.value === ""
                                      ? "--NA--"
                                      : cell.render("Cell")}
                                  </td>
                                );
                              }
                            })}
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <div className="data-not-found">
                      <div className="data-not-found-icon">
                        <BiError />
                      </div>
                      <div className="data-not-found-text">
                        {location.pathname === "/hotlist-form" &&
                        formRedux?.bechSalesId === ""
                          ? "Please Select BenchSales First"
                          : "Data Not Found"}
                      </div>
                    </div>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  return (
    <div className="candidate-hotlist-table-main-div">
      <div className="table-container">{table(allTableData)}</div>
      <CandidateTablePagination
        searchAnyData={searchAnyData}
        paginationData={paginationData}
        setCurrentPage={setCurrentPage}
        setPerPagePagination={setPerPagePagination}
      />
    </div>
  );
};

export default CandidateHotlistTable;
