import { configureStore } from '@reduxjs/toolkit'
import loginSlice from './loginSlice'
import  candidateTableReducer from "./candidateTableSlice"
import manageUserSlice from './manageUserSlice'
import hotlistFormSlice from './hotlistForm'

export const store = configureStore({
  reducer: {
    user:loginSlice,
    candidateTable:candidateTableReducer,
    manage:manageUserSlice,
    form:hotlistFormSlice
  },
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch