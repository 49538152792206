import { Pagination, Select } from 'antd'
import React from 'react'
import "../../style//UserLoginLogOutTime.css";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from 'react-icons/rx';
import { Option } from 'antd/es/mentions';


const UserActivityPagination = () => {
    return (
        <>
            <div className='per-page-user-text'>
            Showing 1 to 10 of 119 entries
            </div>
            <div className='per-page-user-activity'>
                <span className='per-page-user-text'>User activity per page</span>
                <Select
                    defaultValue="10"
                    style={{ width: 55 }}
                >
                    <Option value="10">10</Option>
                    <Option value="20">20</Option>
                    <Option value="50">50</Option>
                    <Option value="100">100</Option>
                </Select>
            </div>
            <div className="user-activity-pagination">
                <span className="nex-prev-disable-in-user-activity">
                    <RxDoubleArrowLeft />
                </span>

                <Pagination defaultCurrent={1} total={50} />

                <span className="nex-prev-disable-in-user-activity">
                    <RxDoubleArrowRight />
                </span>
            </div>
        </>
    )
}

export default UserActivityPagination