import { createSlice } from "@reduxjs/toolkit";

export interface loginState {
  user: any;
}

const userData: any = localStorage.getItem("Ws_Hotlist_App");
const isUser = JSON.parse(userData || null);

const initialState: loginState = {
  user: isUser || {},
};

export const loginSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserLogin: (state, action) => {
      localStorage.setItem("Ws_Hotlist_App", JSON.stringify(action.payload));
      state.user = action.payload;
    }, 
    
    setUserLogout: (state) => {
      localStorage.clear();
      state.user = {}
      window.location.reload();
    }
  },
});

export const { setUserLogin, setUserLogout } = loginSlice.actions;

export default loginSlice.reducer;
