import { BrowserRouter, useRoutes } from "react-router-dom";
import routes from "./routes/routes";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Header from "./pages/Header";

function App() {
  const { user } = useSelector((state: any) => state.user);
  
  
  const isUser = user?.id ? true : false;
  const isAdmin = user.role === "0" ? true : false
  const location = useLocation();

  const routing = useRoutes(routes(isUser, isAdmin));

  return (
    <>
      {location.pathname === "/login" ? null : <Header />}
      {routing}
    </>
  );
}


const AppWrapper = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

export default AppWrapper;
