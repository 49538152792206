import React from 'react'
import "../../style/AddHotlist/dropDownArrow.css"

const DropDownArrow = () => {
    return (
        <>
        <div className="scroll-down"></div>
        <div className="scroll-down-two"></div>
        </>
    )
}

export default DropDownArrow