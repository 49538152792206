import React from 'react';
import { Modal } from 'antd';
import '../../style/DeleteModal.css'

const DeleteModal = ({ confirmDelete, setConfirmDelete, deleteAllTableData }: any) => {



  return (
    <div>
      <>

        <Modal maskStyle={{ backgroundColor: 'rgb(0,0, 0, 0.7)' }} footer={null} className='delete-popup-modal' open={confirmDelete} onOk={() => setConfirmDelete(false)} onCancel={() => setConfirmDelete(false)}>
          <p>Confirm Delete</p>
          <div className='delete-popup-but-div'>
            <button className='delete-yes-but' onClick={() => deleteAllTableData()}>Yes</button>
            <button className='delete-no-but' onClick={() => setConfirmDelete(false)}>No</button>
          </div>
        </Modal>
      </>
    </div>
  )
}

export default DeleteModal