
import "../../style/ManageUsers/addMoreNotes.css";
import { useState } from "react";
import { Button, Modal, message } from "antd";
import { addCandidateNote } from "../../services/HotlistTableData";
import { useDispatch } from "react-redux";
import { setRefresh } from "../../redux/candidateTableSlice";

const AddMoreNotes = ({
  dataById,
  setNewNoteAdd,
  setNoteDetailsModal,
  setAddNoteModalOpen,
  addNoteModalOpen,
}: any) => {
  const [textValue, setTextValue] = useState("");
  const [noteloader, setNoteloader] = useState(false)

  const dispatch = useDispatch();

  const handleNoteSubmit = (id: any) => {
    setNoteloader(true)
    if (textValue.trim().length > 0) {
      addCandidateNote({ candidateId: id, note: textValue }).then(
        () => {
          setNoteloader(false)
          setNewNoteAdd(true);
          dispatch(setRefresh());
          setAddNoteModalOpen(false);
          setTextValue("");
        }
      );
    } else {
      message.error("Please Add Note");
      setNoteloader(false);
    }
  };

  return (
    <div className="add-more-note-details">
      <Modal
        maskStyle={{ backgroundColor: "#000000a3" }}
        className="more-note-modal-open"
        width={900}
        open={addNoteModalOpen}
        afterClose={() => setTextValue("")}
        onOk={() => setAddNoteModalOpen(false)}
        onCancel={() => setAddNoteModalOpen(false)}
        footer={null}
      >
        <div className="add-note-modal-details" style={{ height: "450px" }}>
          <div className="more-note-header">
            <p>Add Notes</p>
          </div>
          <div className="second-note-div-sec">
            <div className="all-note-data">
              <textarea
                value={textValue}
                placeholder="Type Here"
                onChange={(e: any) => setTextValue(e.target.value)}
              />
              <br />
            </div>
            <div className="more-note-under-line"></div>
          </div>
          <div className="add-note-sec-last-click">
            <Button disabled={noteloader} loading={noteloader} onClick={() => { handleNoteSubmit(dataById.id) }} > Done </Button>
          </div>
          <div
            className="more-note-close-item"
            onClick={() => setAddNoteModalOpen(false)}
          >
            <p> x </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddMoreNotes;
