import React from "react";
import "../style/ManageUsers/manageUsers.css";
import { MdDelete } from "react-icons/md";
import { useTable } from "react-table";
import { useState } from "react";
import { useEffect } from "react";
import ManageAddUsers from "../components/popup/ManageAddUsers";
import ManageEditUsers from "../components/popup/ManageEditUsers";
import { deleteUser, getUserData, setUserActive, } from "../services/mangeUserServices";
import { Modal, Space, Spin, Switch } from "antd";
import { FaUser, FaUserSlash } from "react-icons/fa";
import sparkle from "../../src/assets/sparkler.png";
import { BiError } from "react-icons/bi";

const ManageUsers = () => {
  const [allUserData, setAllUserData]: any = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeletePop, setIsDeletePop] = useState(false);
  const [userDeleteId, setUserDeleteId] = useState(null);


  const manageUserData = async () => {
    setIsLoading(true);
    await getUserData()
      .then((response: any) => {
        setAllUserData(response.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    manageUserData();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "User ID",
        accessor: "id",
      },
      {
        Header: "Employee Name",
        accessor: "fullName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Active/Inactive",
        accessor: "isActive",
      },
      {
        Header: "User Type",
        accessor: "role",
      },
      {
        Header: "",
        accessor: "action",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  }: any = useTable({
    columns,
    data: allUserData,
  });

  const onSwitchChange = async (id: number) => {
    await setUserActive(id).then((response: any) => {
      if (response.success) {
        manageUserData();
      }
    });
  };
  const handleDelete = async () => {
    await deleteUser(Number(userDeleteId)).then((response: any) => {
      if (response.success) {
        setIsDeletePop(false);
        manageUserData();
      }
    });
  };



  return (
    <>
      <Modal
        maskStyle={{ backgroundColor: "rgb(0,0, 0, 0.7)" }}
        footer={null}
        className="delete-popup-modal"
        open={isDeletePop}
        onOk={() => {
          setIsDeletePop(false);
        }}
        onCancel={() => {
          setIsDeletePop(false);
        }}
      >
        <p>Confirm Delete</p>
        <div className="delete-popup-but-div">
          <button className="delete-yes-but" onClick={handleDelete}>
            Yes
          </button>
          <button
            className="delete-no-but"
            onClick={() => {
              setUserDeleteId(null);
              setIsDeletePop(false);
            }}
          >
            No
          </button>
        </div>
      </Modal>
      <div className="main-manage-user">
        <div className="manage-first-section">
          <div className="raising-star">
            <span>
              <img src={sparkle} width={"17px"} alt="" />
            </span>
            <div className="user-permission">
              <h3 style={{ fontWeight: "800" }}>Manage Users </h3>
              <p className="addOrRemoveUsers">Add or remove users here and manage permissions</p>
            </div>
          </div>
          <ManageAddUsers manageUserData={manageUserData} />
        </div>
        <div className="border-line"></div>
        <div className="second-section">
          <h4 style={{ fontWeight: "700" }}>Admin Users</h4>
          <p className="adminCanChangePermissions">
            Admin can change permissions and create new users and perform
            organization level changes
          </p>
        </div>
        <div className="third-section">
          <div className="border-radius-for-ManageUser">
          <table {...getTableProps()} className="ManageAddUserTable">
            <thead>
              {headerGroups.map((headerGroup: any) => {
                return (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => {
                      return (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>

            <tbody {...getTableBodyProps()}>
              {isLoading ? (
                <div className="spinner">
                  <Space size="middle">
                    <Spin size="large" wrapperClassName="spin" />
                  </Space>
                </div>
              ) : allUserData.length > 0 ? (
                <>
                  {rows.map((row: any, i: any) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell: any) => {
                          if (cell.column.id === "isActive") {
                            return (
                              <td className="manage-user-switch">
                                <Switch
                                  size={"default"}
                                  defaultChecked
                                  checkedChildren={<FaUser size={12} />}
                                  unCheckedChildren={<FaUserSlash size={12} />}
                                  onClick={() =>
                                    onSwitchChange(cell?.row?.original?.id)
                                  }
                                  checked={cell.value}
                                />
                              </td>
                            );
                          }

                          if (cell.column.id === "role") {
                            return (
                              <td>
                                {cell.value === "0" ? "Admin" : "Recruiter"}
                              </td>
                            );
                          }

                          if (cell.column.id === "action") {
                            return (
                              <td {...cell.getCellProps()}>
                                <>
                                  <div className="manage-two-clicker">
                                    <MdDelete
                                      className="manage-delete-item"
                                      style={{
                                        fontSize: "22px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setUserDeleteId(
                                          cell?.row?.original?.id
                                        );
                                        setIsDeletePop(true);
                                      }}
                                    />
                                    <ManageEditUsers
                                      editData={cell?.row?.original}
                                      manageUserData={manageUserData}
                                    />
                                  </div>
                                </>
                              </td>
                            );
                          } else {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="data-not-found">
                    <div className="data-not-found-icon">
                      <BiError />
                    </div>
                    <div className="data-not-found-text">Data Not Found</div>
                  </div>
                </>
              )}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageUsers;
