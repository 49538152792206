
const exportColumns = [
    {
        Header: 'Record ID',
        accessor: 'id',
    },
    {
        Header: "Full Name",
        accessor: 'fullName',
    },
    {
        Header: 'Title',
        accessor: 'title',
    
    },
    {
        Header: 'Experience',
        accessor: 'experience',
    
    },
    {
        Header: 'Visa Type',
        accessor: 'visaType',
    
    },
    {
        Header: 'City',
        accessor: 'city',
    
    },
    {
        Header: 'State',
        accessor: 'state', 
    },
    {
        Header: 'Relocation',
        accessor: 'relocation',
    
    },
    {
        Header: 'Availability',
        accessor: 'availability',
    
    },
    
    {
        Header: 'Latest Note',
        accessor: 'note',
    },
    {
        Header: 'Created By',
        accessor: 'createdBy',
    
    },
    {
        Header: 'Updated By',
        accessor: 'updatedBy',
    
    },
    {
        Header: 'Created At',
        accessor: 'createdAt',
    
    },
    {
        Header: 'Updated At',
        accessor: 'updatedAt',
    },

    {
        Header: 'Created ID (User)',
        accessor: 'createdId',
    },
    {
        Header: 'Updated ID (User)',
        accessor: 'updatedId',
    },
    {
        Header: 'Deleted or Not',
        accessor: 'isDeleted',
    },
    {
        Header: 'Bench Sales ID',
        accessor: 'benchSaleId',
    },
    {
        Header: 'Bench Sales Business Email',
        accessor: 'businessEmail',
    },
    {
        Header: 'Bench Sales First Name',
        accessor: 'benchSaleFirstName',
    },
    {
        Header: 'Bench Sales Last Name',
        accessor: 'benchSaleLastName',
    },
    {
        Header: 'Bench Sales Hangout ID',
        accessor: 'benchSaleHangoutId',
    },
    {
        Header: 'Bench Sales Phone',
        accessor: 'benchSalePhone',
    },
    {
        Header: 'Bench LinkedIn ID',
        accessor: 'benchSaleLinkedInId',
    },
    {
        Header: 'Bench Sales WhatsApp Number',
        accessor: 'benchSaleWhatsAppNumber',
    },
    {
        Header: 'Bench Sales Location',
        accessor: 'benchSaleLocation',
    },

]

export default exportColumns