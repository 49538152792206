import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, Space, Spin } from "antd";
import AddMoreNotes from "./AddMoreNotes";
import { candidateNoteById } from "../../services/HotlistTableData";
import { MdNote } from "react-icons/md";
import "../../style/ManageUsers/notePopModal.css";
import moment from "moment";

const NotePopModal = ({ dataById, newNoteAdd, setNewNoteAdd }: any) => {
  const [noteDetailsModal, setNoteDetailsModal] = useState(false);
  const [candidateIdForNote, setCandidateIdForNote]: any = useState("");
  const [noteData, setNoteData] = useState([]);
  const [addNoteModalOpen, setAddNoteModalOpen] = useState(false);
  const [noteLoader, setNoteLoader] = useState(false);

  const showNoteModalOpen = (candidateId: any) => {
    setNoteDetailsModal(true);
    setCandidateIdForNote(candidateId);
  };

  useEffect(() => {
    setNoteLoader(true);
    if (!candidateIdForNote) return;

    if (noteDetailsModal) {
      candidateNoteById(dataById?.id).then((ele: any) => {
        setNoteData(ele?.data);
        setNoteLoader(false);
      });
    }
  }, [candidateIdForNote, noteDetailsModal, newNoteAdd]);

  return (
    <div className="main-note-btn">
      <div className="note-click">
        {dataById?.candidateNote === "" ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "#A4A5A5",
                opacity: "1",
              }}
              onClick={() => setAddNoteModalOpen(true)}
            >
              <MdNote />
              <p>Add Note</p>
            </div>
          </>
        ) : (
          <div onClick={() => showNoteModalOpen(dataById?.id)}>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <MdNote />
              <p
                style={{
                  maxWidth: "150px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {dataById?.candidateNote}
              </p>
            </span>
          </div>
        )}
      </div>

      <Modal
        maskStyle={{ backgroundColor: "#000000a3" }}
        className="note-modal-open-close"
        width={900}
        centered={true}
        open={noteDetailsModal}
        onOk={() => setNoteDetailsModal(false)}
        onCancel={() => setNoteDetailsModal(false)}
        footer={null}
      >
        <div className="note-one-two-three-sec" style={{ height: "440px" }}>
          <div className="note-header">
            <p>Notes</p>
          </div>

          <div className="note-create-date-by">
            <div className="note-second-first-sec" style={{ flexBasis: "40%" }}>
              <p>Notes</p>
            </div>
            <div className="note-last-details" style={{ flexBasis: "28%" }}>
              <p>Created date</p>
            </div>
            <div className="note-create-by" style={{ flexBasis: "32%" }}>
              <p>Created By</p>
            </div>
          </div>
          <div className="note-all-data-main-div">
            {noteLoader ? (
              <Space size="middle" className="noteLoader">
                <Spin size="large" />
              </Space>
            ) : (
              <>
                {noteData.map((ele: any) => {
                  return (
                    <div className="note-all-data">
                      <div
                        className="note-second-details"
                        style={{ padding: "20px 0 0 25px" }}
                      >
                        <div
                          className="note-detail-date-sec"
                          style={{ width: "35%" }}
                        >
                          <p>{ele?.note}</p>
                        </div>
                        <div
                          className="create-date"
                          style={{ width: "28%", paddingLeft: "30px" }}
                        >
                          <p>
                            {moment(ele?.createdAt).format(
                              "MMMM Do YYYY, h:mm:ss A"
                            )}
                          </p>
                        </div>
                        <div
                          className="create-by-last"
                          style={{ width: "30%", paddingLeft: "12px" }}
                        >
                          <p>{ele?.updatedBy}</p>
                        </div>
                      </div>
                      <div className="note-details-under-line"></div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div
            className="note-close-item"
            onClick={() => setNoteDetailsModal(false)}
          >
            <p> x </p>
          </div>
          <div className="add-more-note">
            <div className="add-last-note" onClick={() => {
              setAddNoteModalOpen(true);
              setNoteDetailsModal(false);
            }}>
              <span> + </span>
              <p>Add More Notes</p>
            </div>
          </div>
        </div>
      </Modal>
      <AddMoreNotes
        dataById={dataById}
        setNewNoteAdd={setNewNoteAdd}
        setNoteDetailsModal={setNoteDetailsModal}
        addNoteModalOpen={addNoteModalOpen}
        setAddNoteModalOpen={setAddNoteModalOpen}
      />
    </div>
  );
};

export default NotePopModal;
