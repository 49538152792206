import { useEffect, useState } from "react";
import { Modal, Space, Spin, Tooltip, Button, Popover } from "antd";
import "../../style//BenchsalesModal.css";
import { getBenchSalesById } from "../../services/HotlistTableData";
import { IoCloseOutline } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";
import AddBenchsalesPopUp from "../addHotlist/AddBenchsalesPopUp";
import { useDispatch, useSelector } from "react-redux";
import { setBenchFilter, setFilterData } from "../../redux/candidateTableSlice";
import { Link } from "react-router-dom";
import { BsLinkedin } from "react-icons/bs";



const BenchsalesModal = ({ name, setCurrentPage }: any) => {
  const [isBenchSalesOpen, setIsBenchSalesOpen] = useState(false);
  const [isBenchsalesDetails, setIsBenchsalesDetails] = useState(false);
  const [isBenchsalesLoader, setIsBenchsalesLoader] = useState(false);
  // const [isOpenSpecificPopup, setIsOpenSpecificPopup] = useState(false);
  const [isBenchsalesData, setsBenchsalesData]: any = useState({});
  const [idForUpdate, setIdForUpdate]: any = useState("");
  const obj: any = useSelector((state: any) => state?.candidateTable);
  const dispatch: any = useDispatch();
  const [isBenchSaleAdded, setIsBenchSaleAdded] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const updateFunForUpdate = (e: any) => {
    setIsBenchSalesOpen(true);
    setIdForUpdate(e);
  };

  useEffect(() => {
    if (!isBenchsalesDetails) return;
    setIsBenchsalesLoader(true);
    getBenchSalesById(name?.benchSaleId).then((e: any) => {
      setsBenchsalesData(e.data);
      setIsBenchsalesLoader(false);
    });
  }, [isBenchsalesDetails, isBenchSalesOpen]);

  return (
    <>
      <div>
        <span
          onMouseEnter={() => setIsPopUpOpen(true)}
          onMouseLeave={() => setIsPopUpOpen(false)}
          style={{
            maxWidth: "140px",
            overflow: "Hidden",
            textOverflow: "ellipsis",
            display: "block"
          }}
          onClick={() => {
            setIsBenchsalesDetails(true);
          }}
        >
          {/* <Popover content={() => name?.fullName} placement="left" > */}
          {name?.fullName === "" ? "Name not Available" : name?.fullName}
          {/* </Popover> */}
        </span>
        {/* <div>
          <div style={{ marginLeft: "buttonWidth", whiteSpace: 'nowrap' }}>
              <Button>TL</Button>
          </div>
        </div> */}
        <Modal
          maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
          className="bench-sales-modal"
          open={isBenchsalesDetails}
          onCancel={() => setIsBenchsalesDetails(false)}
          footer={null}
        >
          <div className="bench-sales-modal-header-div">
            <h4 className="bench-sales-modal-header">
              Benchsales Contact Details
            </h4>
          </div>
          {isBenchsalesLoader ? (
            <>
              <Space size="middle">
                <Spin size="large" />
              </Space>
            </>
          ) : (
            <div className="bench-sales-modal-table">
              <div className="bench-sales-modal-table-tbody">
                <div style={{ width: "47%", paddingLeft: "65px" }}>
                  <h4>First Name</h4>
                </div>

                <p style={{ width: "10%" }}>:</p>

                <div
                  style={{ width: "50%" }}
                  onClick={() => {
                    setCurrentPage(1);
                    dispatch(
                      setFilterData({
                        ...obj?.filterData,
                        specialBody: {
                          benchSaleIds: [name?.benchSaleId],
                          domains: obj?.filterData?.specialBody?.domains || [],
                        },
                      })
                    );

                    if (
                      !obj.benchFilter.includes(
                        isBenchsalesData?.firstName +
                        " " +
                        isBenchsalesData?.lastName
                      )
                    ) {
                      dispatch(
                        setBenchFilter([
                          ...obj.benchFilter,
                          isBenchsalesData?.firstName +
                          " " +
                          isBenchsalesData?.lastName,
                        ])
                      );
                    }
                    setIsBenchsalesDetails(false);
                  }}
                >
                  <u
                    style={{
                      color: "#0064C8",
                      cursor: "pointer",
                      fontWeight: "500",
                    }}
                    onClick={() => {
                      setCurrentPage(1);
                      dispatch(
                        setFilterData({
                          ...obj?.filterData,
                          specialBody: {
                            benchSaleIds: [name?.benchSaleId],
                            domains: obj?.filterData?.specialBody?.domains || [],
                          },
                        })
                      );

                      if (
                        !obj.benchFilter.includes(
                          isBenchsalesData?.firstName +
                          " " +
                          isBenchsalesData?.lastName
                        )
                      ) {
                        dispatch(
                          setBenchFilter([
                            ...obj.benchFilter,
                            isBenchsalesData?.firstName +
                            " " +
                            isBenchsalesData?.lastName,
                          ])
                        );
                      }
                      setIsBenchsalesDetails(false);
                    }}
                  >
                    {isBenchsalesData?.firstName}
                  </u>
                </div>
              </div>
              <div className="bench-sales-modal-table-tbody">
                <div style={{ width: "47%", paddingLeft: "65px" }}>
                  <h4>Last Name</h4>
                </div>

                <p style={{ width: "10%" }}>:</p>

                <div style={{ width: "50%", fontWeight: "500" }}>
                  {isBenchsalesData?.lastName !== "" &&
                    isBenchsalesData?.lastName !== "(NA)" &&
                    isBenchsalesData?.lastName !== undefined ? (
                    <p>{isBenchsalesData?.lastName}</p>
                  ) : (
                    "--NA--"
                  )}
                </div>
              </div>

              <div className="bench-sales-modal-table-tbody">
                <div style={{ width: "47%", paddingLeft: "65px" }}>
                  <h4>Business Email</h4>
                </div>

                <p style={{ width: "10%" }}>:</p>

                <div style={{ width: "50%", fontWeight: "500" }}>
                  <p>
                    {isBenchsalesData?.businessEmail?.split("@")[0]}
                    {isBenchsalesData?.businessEmail ? "@" : null}
                    <span
                      style={{ color: "#0064C8", cursor: "pointer" }}
                      onClick={() => {
                        setCurrentPage(1);
                        dispatch(
                          setFilterData({
                            ...obj?.filterData,
                            specialBody: {
                              benchSaleIds:
                                obj?.filterData.specialBody.benchSaleIds,
                              domains: [
                                isBenchsalesData?.businessEmail?.split("@")[1],
                              ],
                            },
                          })
                        );
                        if (
                          !obj.benchFilter.includes(
                            isBenchsalesData?.businessEmail?.split("@")[1]
                          )
                        ) {
                          dispatch(
                            setBenchFilter([
                              ...obj.benchFilter,
                              isBenchsalesData?.businessEmail?.split("@")[1],
                            ])
                          );
                        }
                      }}
                    >
                      {isBenchsalesData?.businessEmail?.split("@")[1]}
                    </span>
                  </p>
                </div>
              </div>

              <div className="bench-sales-modal-table-tbody">
                <div style={{ width: "47%", paddingLeft: "65px" }}>
                  <h4>Hangout ID</h4>
                </div>

                <p style={{ width: "10%" }}>:</p>

                <div style={{ width: "50%", fontWeight: "500" }}>
                  {isBenchsalesData?.hangoutId !== "" &&
                    isBenchsalesData?.hangoutId !== undefined ? (
                    <p>{isBenchsalesData?.hangoutId}</p>
                  ) : (
                    "--NA--"
                  )}
                </div>
              </div>
              <div className="bench-sales-modal-table-tbody">
                <div style={{ width: "47%", paddingLeft: "65px" }}>
                  <h4>Phone No.</h4>
                </div>

                <p style={{ width: "10%" }}>:</p>

                <div style={{ width: "50%", fontWeight: "500" }}>
                  {isBenchsalesData?.phone !== "" &&
                    isBenchsalesData?.phone !== undefined ? (
                    <p>{isBenchsalesData?.phone}</p>
                  ) : (
                    "--NA--"
                  )}
                </div>
              </div>
              <div className="bench-sales-modal-table-tbody">
                <div style={{ width: "47%", paddingLeft: "65px" }}>
                  <h4>LinkedIn ID</h4>
                </div>
                <p style={{ width: "10%" }}>:</p>
                <div style={{ width: "50%", fontWeight: "500" }}>
                  {isBenchsalesData?.linkedInId !== "" &&
                    isBenchsalesData?.linkedInId !== undefined ? (
                    <Tooltip
                      placement="top"
                      title={isBenchsalesData?.linkedInId}
                      color={"blue"}
                      key={"white"}
                    >
                      <a href={!isBenchsalesData?.linkedInId?.includes("http") ? `https://${isBenchsalesData?.linkedInId}` : isBenchsalesData?.linkedInId} target="_blank">
                        <BsLinkedin />
                      </a>
                      {/* <Link to={isBenchsalesData?.linkedInId} target="_blank">
                        <BsLinkedin />
                      </Link> */}
                    </Tooltip>
                  ) : (
                    "--NA--"
                  )}
                </div>
              </div>
              <div className="bench-sales-modal-table-tbody">
                <div style={{ width: "47%", paddingLeft: "65px" }}>
                  <h4>WhatsApp Number</h4>
                </div>

                <p style={{ width: "10%" }}>:</p>

                <div style={{ width: "50%", fontWeight: "500" }}>
                  {isBenchsalesData?.whatsAppNumber !== "" &&
                    isBenchsalesData?.whatsAppNumber !== undefined ? (
                    <p>{isBenchsalesData?.whatsAppNumber}</p>
                  ) : (
                    "--NA--"
                  )}
                </div>
              </div>
              <div className="bench-sales-modal-table-body">
                <div style={{ width: "47%", paddingLeft: "65px" }}>
                  <h4>Location</h4>
                </div>

                <p style={{ width: "10%" }}>:</p>

                <div style={{ width: "50%", fontWeight: "500" }}>
                  {isBenchsalesData?.location !== "" &&
                    isBenchsalesData?.location !== undefined ? (
                    <p>{isBenchsalesData?.location}</p>
                  ) : (
                    "--NA--"
                  )}
                </div>
              </div>
              <button
                className="bench-sales-popup-edit-button"
                onClick={() => updateFunForUpdate(isBenchsalesData?.id)}
              >
                <FaEdit />
              </button>
            </div>
          )}
          <button
            className="popup-default-close-button"
            onClick={() => setIsBenchsalesDetails(false)}
          >
            <IoCloseOutline />
          </button>
        </Modal>

        <AddBenchsalesPopUp
          idForUpdate={idForUpdate}
          isBenchSalesOpen={isBenchSalesOpen}
          setIsBenchSalesOpen={setIsBenchSalesOpen}
          isBenchsalesData={isBenchsalesData}
          setsBenchsalesData={setsBenchsalesData}
          isBenchSaleAdded={isBenchSaleAdded}
          setIsBenchSaleAdded={setIsBenchSaleAdded}
        />
      </div>
      {/* <SpecificBenchSalesModal
        setIsBenchsalesDetails={setIsBenchsalesDetails}
        isBenchsalesData={isBenchsalesData}
        isOpenSpecificPopup={isOpenSpecificPopup}
        setIsOpenSpecificPopup={setIsOpenSpecificPopup}
      /> */}

      {
        name?.fullName &&
        <Tooltip open={isPopUpOpen} title={name?.fullName} placement="bottomLeft" >
        </Tooltip>
      }
    </>
  );
};

export default BenchsalesModal;
