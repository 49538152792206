import Http from "./http";

export const addUser = async (data:any)=> {
    return await Http.post("admin/add-user",data,true);
}

export const getUserData = async () => {
    return await Http.get('admin/getUser', false)
}

export const getUserEditData = async (data:any) => {
    return await Http.put('admin/updateUser',data, true)
}

export const setUserActive = async (id:number)=> {
    return await Http.put(`admin/userActiveStatus/${id}`,{}, true)
}

export const deleteUser = async (id:number)=> {
    return await Http.delete(`admin/deleteUser/${id}`,{},true)
}