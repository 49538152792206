import React from 'react'
import '../../style/UserActivityTable.css'

const UserActivityTable = ({ open }: any) => {
  // Dummy data
  const dummyData = [
    { userName: 'rohit', loginTime: '10:20', count: 5 },
    { userName: 'john', loginTime: '11:30', count: 3 },
    { userName: 'emma', loginTime: '09:45', count: 7 },
    { userName: 'emma', loginTime: '09:45', count: 7 },
    { userName: 'emma', loginTime: '09:45', count: 7 },
    { userName: 'emma', loginTime: '09:45', count: 7 },
    { userName: 'emma', loginTime: '09:45', count: 7 },
    { userName: 'emma', loginTime: '09:45', count: 7 },
    { userName: 'emma', loginTime: '09:45', count: 7 },
    { userName: 'emma', loginTime: '09:45', count: 7 },
    { userName: 'emma', loginTime: '09:45', count: 7 },
    { userName: 'emma', loginTime: '09:45', count: 7 },
    { userName: 'emma', loginTime: '09:45', count: 7 },
    { userName: 'emma', loginTime: '09:45', count: 7 },
    { userName: 'emma', loginTime: '09:45', count: 7 },
    { userName: 'emma', loginTime: '09:45', count: 7 },
  ];

  return (
    <div className='main-table-div'>
      <table>
        <thead>
          <tr>
            <th className='title-th'>User Name</th>
            {!open ? (
              <th className='title-th'>Login Time</th>
            ) : (
              <th className='title-th'>Count</th>
            )}
          </tr>
        </thead>
        <tbody className='user-activity-body-section'>
          {dummyData.map((data, index) => (
            <tr key={index}>
              <td>{data.userName}</td>
              {!open ? <td>{data.loginTime}</td> : <td>{data.count}</td>}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default UserActivityTable
