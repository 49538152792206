
import UserLoginLogOutTime from "../components/loginActivities/UserLoginLogOutTime";
import AddHotlist from "../pages/AddHotlist";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import ManageUsers from "../pages/ManageUsers";
import { Navigate } from "react-router-dom";


const routes: any = (isUser: any, isAdmin: any) => [
  {
    path: "/",
    element: isUser ? <Dashboard /> : <Navigate to={"/login"} />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
    ],
  },

  {
    path: "/hotlist-form",
    element: isUser ? <AddHotlist /> : <Navigate to={"/login"} />,
    children: [
      {
        path: "hotlist-form",
        element: <AddHotlist />,
      },
    ],
  },
  {
    path: "/manageusers",
    element: isAdmin ? <ManageUsers /> : <Navigate to={"/login"} />,
    children: [
      {
        path: "manageusers",
        element: <ManageUsers />,
      },
    ],
  },
  {
    path: "/",
    element: !isUser ? <Login /> : <Navigate to={"/"} />,
    children: [
      {
        path: "/login",
        element: <Login />
      }
    ]
  },
  {
    path: "/login",
    element: <Login />,
  },
  // {
  //   path: "/loginactivity",
  //   element: <UserLoginLogOutTime />,
  // },
  {
    path: "/loginactivity",
    element: isAdmin ? <UserLoginLogOutTime /> : <Navigate to={"/login"} />,
    children: [
      {
        path: "loginactivity",
        element: <UserLoginLogOutTime />,
      },
    ],
  },

];

export default routes;
