import '../../style/ManageUsers/manageEditUsers.css'
import { FiEdit3 } from 'react-icons/fi';
import { useState, useRef } from "react";
import { Button, Modal, message } from "antd";
import { Select } from "antd";
import { getUserEditData } from "../../services/mangeUserServices";



const ManageEditUsers = ({ manageUserData, editData }: any) => {

  const [addUserData, setAddUserData]: any = useState({
    id: editData.id,
    fullName: editData.fullName,
    email: editData.email,
    password: editData.password,
    role: editData.role,
    phone: editData.phone,
  });

  const [modalEditUser, setModalEditUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors]: any = useState({});



  const showModalEditUser = () => {
    setModalEditUser(true);
  };

  const formRef: any = useRef(null);

  const { Option } = Select;

  function handleChange(e: any) {
    setAddUserData({ ...addUserData, role: e });
  }

  const handleUser = async (e: any) => {
    e.preventDefault();
    formRef.current.reportValidity();
    if (formRef.current.reportValidity()) {
      setIsLoading(true);
      await getUserEditData(addUserData)
        .then((response: any) => {
          if (response.success) {
            setIsLoading(false);
            manageUserData();
            setModalEditUser(false);
          }
        }).catch((error: any) => {
          // console.log(error);
          if (error.status === 400) {
            message.error(error?.data?.errors[0]?.msg)
            // setFormErrors(error?.data?.errors)
            let errorObj = {}
            error?.data?.errors?.forEach((error: any) => {
              errorObj = {
                ...errorObj,
                [`${error.param}`]: error.msg
              }
            })
            setFormErrors(errorObj)
            // setFormErrors()
          }
          setIsLoading(false);
        })
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="main-add-user">
      <div className="first-sec-last-click">
        <FiEdit3 size={18} onClick={showModalEditUser} style={{ cursor: "pointer" }} />
      </div>
      <Modal
        maskStyle={{ backgroundColor: "#000000a3" }}
        className="modal-open-user"
        width={600}
        open={modalEditUser}
        afterClose={() =>{ setAddUserData({
          id: editData.id,
          fullName: editData.fullName,
          email: editData.email,
          password: "",
          role: editData.role,
          phone: editData.phone,
        });
        setFormErrors({})
      }

      }
        onOk={() => setModalEditUser(false)}
        // onCancel={() => setModalEditUser(false)}
        footer={null}
      >
        <form
          className="pop-user-modal"
          style={{ height: "fit-content" }}
          ref={formRef}
          onSubmit={handleUser}
        >
          <div className="user-header">
            <p>Edit Users</p>
            <div className="close-box" onClick={() => setModalEditUser(false)}>
              <span className="cross-btn">X</span>
            </div>
          </div>
          <div className="ass-user-details">
            <input
              type="text"
              value={addUserData.fullName}
              placeholder="User Name"
              onChange={(e: any) => {
                setAddUserData({ ...addUserData, fullName: e.target.value });
              }}
              required
            />
            {formErrors?.fullName && <span className="formValidateError">{formErrors?.fullName}</span>}

            <Select
              className="modal-select-manage"
              value={addUserData.role}
              style={{ width: '300px' }}
              onChange={handleChange}
            >
              <Option value="" disabled selected>User Type</Option>
              <Option value="0">Admin</Option>
              <Option value="1"> Recruiter </Option>
            </Select>
            {formErrors?.role && <span className="formValidateError">{formErrors?.role}</span>}

            <input
              type="email"
              value={addUserData.email}
              placeholder="Email"
              onChange={(e: any) => {
                setAddUserData({ ...addUserData, email: e.target.value });
              }}
              required
            />
            {formErrors?.email && <span className="formValidateError">{formErrors?.email}</span>}

            <input
              type="tel"
              value={addUserData.phone}
              placeholder="Phone"
              onChange={(e: any) => {
                setAddUserData({ ...addUserData, phone: e.target.value });
              }} required
            />
            {formErrors?.phone && <span className="formValidateError">{formErrors?.phone}</span>}

            <input
              type="password"
              value={addUserData.password}
              placeholder="Password"
              onChange={(e: any) => { setAddUserData({ ...addUserData, password: e.target.value }); }}
            />
            {formErrors?.password  && <span className="formValidateError">{formErrors?.password}</span>}

          </div>
          <div className="user-add-click">
            <Button
              loading={isLoading}
              className="user-btn"
              htmlType='submit'
            >
              Update
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  )
}

export default ManageEditUsers
