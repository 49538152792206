import { Pagination, Select } from "antd";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";

const CandidateTablePagination = ({
  paginationData,
  setCurrentPage,
  setPerPagePagination,
  searchAnyData,
}: any) => {
  const { Option } = Select;

  return (
    <div className="candidateHotlistAntdPaginationMainDiv">
      <div className="showingPageination">
        Showing{" "}
        {paginationData?.totalRecords === 0
          ? 0
          : (paginationData?.currentPage - 1) * paginationData?.totalPerpage +
          1}{" "}
        to{" "}
        {paginationData?.totalRecords === 0
          ? 0
          : paginationData?.nextPage !== null
            ? paginationData?.totalPerpage * paginationData?.currentPage
            : paginationData?.totalRecords}{" "}
        of {paginationData?.totalRecords} entries
      </div>

      <div className="candidatesPerPageDiv">
        Candidates per page
        <Select
          defaultValue="10"
          style={{ width: 55 }}
          onChange={(e: any) => { setPerPagePagination(e);setCurrentPage(1) }}
        >
          <Option value="10">10</Option>
          <Option value="20">20</Option>
          <Option value="50">50</Option>
          <Option value="100">100</Option>
        </Select>
      </div>
      <div className="paginationMainDiv">
        <span className={paginationData?.currentPage === 1 ? "nex-prev-disable" : "nex-prev"} onClick={() => { setCurrentPage(1) }}>
          <RxDoubleArrowLeft  />  
        </span>
        <Pagination
          defaultCurrent={1}
          showQuickJumper
          pageSize={paginationData?.totalPerpage || 10}
          // current={searchAnyData?.length > 0 ? 1 : paginationData?.currentPage}
          current={paginationData?.currentPage}
          total={paginationData?.totalRecords}
          onChange={(e: any) => { setCurrentPage(e); }}
        />
        <span className={paginationData?.currentPage === paginationData?.totalPage ? "nex-prev-disable" : "nex-prev"}
          onClick={() => { setCurrentPage(paginationData?.totalPage) }} >
          <RxDoubleArrowRight />
        </span>
      </div>
    </div>
  );
};

export default CandidateTablePagination;
