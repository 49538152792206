/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useRef } from "react";
import "../../style/AddHotlist/AddHotlistForm.css";
import Http from "../../services/http";
import "animate.css";
import { BsCheckCircleFill } from "react-icons/bs";
import { Button, Divider, Input, Select, Space, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { refreshForm, setBenchSalesId } from "../../redux/hotlistForm";
import { useLocation, useNavigate } from "react-router-dom";
import type { InputRef } from 'antd';

const AddHotlistForm = ({
  // isBenchSalesOpen,
  setIsBenchSalesOpen,
  isBenchSaleAdded,
}: any) => {
  const [isCandidateAdded, setIsCandidateAdded] = useState(false);
  const [getBenchSalesData, setGetBenchSalesData]: any = useState([]);
  const location = useLocation();
  const [hotlistData, setHotlistData]: any = useState({
    search: location.state ? location?.state?.benchSaleId : "",
    fullName: location.state ? location?.state?.fullName : "",
    title: location.state ? location?.state?.title : "",
    experience: location.state ? location?.state?.experience : "",
    visaType: location.state ? location?.state?.visaType : "",
    state: location.state ? location?.state?.state : "",
    city: location.state ? location?.state?.city : "",
    relocation: location.state ? location?.state?.relocation : "",
    availability: location.state ? location?.state?.availability : "",
  });
  const [isformSubmitLoader, setIsformSubmitLoader]: any = useState(false);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [formErrors, setFormErrors]: any = useState({});
  const [isBenchSaleListLoading, setIsBenchSaleListLoading] = useState(true);
  const [visaTypeList, setVisaTypeList] = useState(['H1B', 'H1B Transfer', 'USC', 'GC', 'GC-EAD', 'E2', 'E2-EAD', 'E3', 'E3-EAD', 'H4-EAD', 'Initial OPT-EAD', ' Stem OPT-EAD', 'L2-EAD', 'TN', 'J2', 'J2-EAD', 'G4-EAD', 'Indian Citizen', 'L2-S']);
  const [name, setName] = useState('');
  const inputRef = useRef<InputRef>(null);

  const formSubmit = async (e: any) => {
    setIsformSubmitLoader(true);
    e.preventDefault();
    const body: any = {
      benchSaleId: hotlistData.search,
      fullName: hotlistData.fullName,
      title: hotlistData.title,
      experience: hotlistData.experience,
      visaType: hotlistData.visaType,
      state: hotlistData.state,
      city: hotlistData.city,
      relocation: hotlistData.relocation,
      availability: hotlistData.availability,
    };
    if (location.state === null) {
      const result: any = await Http.post("candidate/add-candidate", body, true)
        .then((response: any) => {
          if (response.success) {
            setIsCandidateAdded(true);
            setIsformSubmitLoader(false);
            setHotlistData({
              ...hotlistData,
              // benchSaleId: "",
              fullName: "",
              title: "",
              experience: "",
              visaType: "",
              state: "",
              city: "",
              relocation: "",
              availability: "",
            });
            // dispatch(setBenchSalesId(""));
            dispatch(refreshForm());
            setFormErrors({});
          } else {
            setFormErrors({});
            setIsCandidateAdded(false);
            setIsformSubmitLoader(false);
          }
        })
        .catch((error: any) => {
          if (error.status === 400) {
            message.error(error?.data?.errors[0]?.msg);
            let errorObj = {};
            error?.data?.errors?.forEach((error: any) => {
              errorObj = {
                ...errorObj,
                [`${error.param}`]: error.msg,
              };
            });
            setFormErrors(errorObj);
          } else {
            setFormErrors({});
          }
          setIsformSubmitLoader(false);
        });
    } else {
      const result: any = await Http.put(
        "candidate/update-candidate",
        { ...body, id: location?.state?.id },
        true
      )
        .then((response: any) => {
          if (response.success) {
            // setIsCandidateAdded(true)
            setIsformSubmitLoader(false);
            navigate("/dashboard");
            setHotlistData({
              benchSaleId: "",
              fullName: "",
              title: "",
              experience: "",
              visaType: "",
              state: "",
              city: "",
              relocation: "",
              availability: "",
            });
          } else {
            setIsformSubmitLoader(false);
          }
        })
        .catch((error: any) => {
          if (error.status === 400) {
            message.error(error?.data?.errors[0]?.msg);
            let errorObj = {};
            error?.data?.errors?.forEach((error: any) => {
              errorObj = {
                ...errorObj,
                [`${error.param}`]: error.msg,
              };
            });
            setFormErrors(errorObj);
          } else {
            setFormErrors({});
          }
          setIsformSubmitLoader(false);
        });
    }
  };

  useEffect(() => {
    Http.get("benchsales/getBenchSales", false).then((response: any) => {
      const valueLabels: any = response?.data?.map((e: any) => {
        return {
          value: e.id,
          label:
            e.firstName != "" || e.lastName != "" ? (
              <span>
                {e.firstName} {e.lastName}{" "}
                <u style={{ fontWeight: "bolder" }}>({e.businessEmail})</u>{" "}
              </span>
            ) : (
              <span>{e.businessEmail}</span>
            ),
        };
      });

      setGetBenchSalesData(valueLabels);
      setIsBenchSaleListLoading(false);
    });
  }, [isBenchSaleAdded]);

  const isObject = (value: any) => {
    return typeof value === "object" && value !== null && !Array.isArray(value);
  };




  const onNameChange = (event: any) => {
    setName(event.target.value);
  };

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    if (!name) return
    setVisaTypeList([...visaTypeList, name]);
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0)
  };

  // Added Blank in Visa Type
  let visaTypeOption = visaTypeList.map((item) => ({ label: item, value: item }))
  visaTypeOption = [
    { label: "None", value: "" },
    ...visaTypeOption,
  ]

  return (
    <>
      {!isCandidateAdded ? (
        <form className="main-form-sec" onSubmit={formSubmit} autoComplete="on">
          <div className="hotlist-website-Input-section">
            <div className="hotlistFormSection">
              <Select
                // open={true}
                popupClassName="addhotlist_popup_style"
                className={hotlistData?.search ? "" : "addForm-select"}
                showSearch
                bordered={false}
                style={{
                  width: "21.5vw",
                  background: "white",
                  fontWeight: "600",
                }}
                loading={isBenchSaleListLoading}
                // optionFilterProp="children"
                onChange={(e: any) => {
                  setHotlistData({ ...hotlistData, search: e });
                  dispatch(setBenchSalesId(e));
                }}
                placeholder="Search Benchsales"
                value={hotlistData?.search || "Search Benchsales"}
                onSearch={(value: string) => value}
                // filterOption={(input: any, option: any) =>
                //   (option?.label ?? "")
                //     .toLowerCase()
                //     .includes(input.toLowerCase())
                // }
                filterOption={(inputValue, option) => {
                  if (
                    typeof option?.label?.props?.children === "object" &&
                    option?.label?.props?.children !== null
                  ) {
                    const plainArr = option?.label?.props?.children?.filter(
                      (ele: any) => !isObject(ele)
                    );
                    const emailText = option?.label?.props?.children
                      ?.filter((ele: any) => isObject(ele))?.[0]
                      ?.props?.children?.[1].toLowerCase();

                    return (
                      plainArr
                        ?.join("")
                        ?.toLowerCase()
                        ?.includes(inputValue?.toLowerCase()) ||
                      emailText?.includes(inputValue?.toLowerCase())
                    );
                  } else {
                    return option?.label?.props?.children
                      ?.toLowerCase()
                      ?.includes(inputValue?.toLowerCase());
                  }
                }}
                dropdownRender={(menu) => {
                  return (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <Space style={{ padding: "0 8px 4px" }}>
                        <div onClick={() => setIsBenchSalesOpen(true)}>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              height: "30px",
                              cursor: "pointer",
                            }}
                          >
                            <p style={{
                              color: "#A4A5A5",
                              fontSize: "15px",
                              fontFamily: "Mulish",
                              fontWeight: "600",
                            }}>Add Benchsales </p>
                            <p style={{ fontWeight: "600" }}>
                              <PlusOutlined />
                            </p>
                          </div>
                        </div>
                      </Space>
                    </>
                  );
                }}
                options={[...getBenchSalesData]}
              />
              {formErrors?.benchSaleId && (
                <span className="formValidateError">
                  {formErrors?.benchSaleId}
                </span>
              )}
            </div>
          </div>
          <div className="hotlist-website-Input-section">
            <div className="hotlistFormSection">
              <input
                name="name"
                value={hotlistData?.fullName}
                onChange={(e: any) =>
                  setHotlistData({ ...hotlistData, fullName: e.target.value })
                }
                type="text"
                className="hotList_all_input"
                placeholder="Full Name"
              // required
              />
              {formErrors?.fullName && (
                <span className="formValidateError">
                  {formErrors?.fullName}
                </span>
              )}
            </div>
            <div className="hotlistFormSection">
              <input
                name="candidate-title"
                value={hotlistData.title}
                onChange={(e: any) =>
                  setHotlistData({ ...hotlistData, title: e.target.value })
                }
                type="text"
                className="hotList_all_input"
                placeholder="Title"
                required
              />
              {formErrors?.title && (
                <span className="formValidateError">{formErrors?.title}</span>
              )}
            </div>
            <div className="hotlistFormSection">
              <input
                name="experience"
                value={hotlistData.experience}
                onChange={(e: any) =>
                  setHotlistData({ ...hotlistData, experience: e.target.value })
                }
                type="text"
                className="hotList_all_input"
                placeholder="Experience"
              // required
              />
              {formErrors?.experience && (
                <span className="formValidateError">
                  {formErrors?.experience}
                </span>
              )}
            </div>
          </div>
          <div className="hotlist-website-Input-section">
            <div className="hotlistFormSection">
              <Select
                // open={true}
                onFocus={() => setName("")}
                popupClassName="addhotlist_popup_style"
                showSearch
                bordered={false}
                onChange={(e: any) => {
                  setHotlistData({ ...hotlistData, visaType: e })
                }}
                style={{
                  width: "21.5vw",
                  background: "white",
                  fontWeight: "600",
                }}
                placeholder="Visa Type"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space style={{ padding: '0 5px 4px', border: '1px solid #a7abab', borderRadius: '10px' }}>
                      <Input
                        bordered={false}
                        style={{ width: '250px' }}
                        name="visa"
                        placeholder="Add Visa Type"
                        ref={inputRef}
                        value={name}
                        onChange={onNameChange}
                      />
                      <Button type="text" icon={<PlusOutlined style={{ fontWeight: "800" }} />} onClick={addItem}>
                      </Button>
                    </Space>
                  </>
                )}
                options={visaTypeOption}
              />

              {formErrors?.visaType && (
                <span className="formValidateError">
                  {formErrors?.visaType}
                </span>
              )}
            </div>

            <div className="notForFlex">

              <div className="hotlistFormSection">
                <input
                  name="city"
                  value={hotlistData.city}
                  onChange={(e: any) =>
                    setHotlistData({ ...hotlistData, city: e.target.value })
                  }
                  type="text"
                  className="location-state"
                  placeholder="Location (City)"
                // required
                />
                {formErrors?.city && (
                  <span className="formValidateError">{formErrors?.city}</span>
                )}
              </div>

              <div className="hotlistFormSection">
                <input
                  name="state"
                  value={hotlistData.state}
                  onChange={(e: any) =>
                    setHotlistData({ ...hotlistData, state: e.target.value })
                  }
                  type="text"
                  className="location-state"
                  placeholder="Location (State)"
                // required
                />
                {formErrors?.state && (
                  <span className="formValidateError">{formErrors?.state}</span>
                )}
              </div>

            </div>
          </div>
          <div className="hotlist-website-Input-section">
            <div className="hotlistFormSection">
              <input
                name="relocation"
                value={hotlistData.relocation}
                onChange={(e: any) =>
                  setHotlistData({ ...hotlistData, relocation: e.target.value })
                }
                type="text"
                className="hotList_all_input"
                placeholder="Relocation"
              // required
              />
              {formErrors?.relocation && (
                <span className="formValidateError">
                  {formErrors?.relocation}
                </span>
              )}
            </div>
            <div className="hotlistFormSection">
              <input
                name="availability"
                value={hotlistData.availability}
                onChange={(e: any) =>
                  setHotlistData({
                    ...hotlistData,
                    availability: e.target.value,
                  })
                }
                type="text"
                className="hotList_all_input"
                placeholder="Availability"
              // required
              />
              {formErrors?.availability && (
                <span className="formValidateError">
                  {formErrors?.availability}
                </span>
              )}
            </div>
          </div>
          <div className="submit-btnSec">
            <Button htmlType="submit" loading={isformSubmitLoader}>
              {location.state === null ? "Submit" : "Update"}
            </Button>
          </div>
        </form >
      ) : (
        <div className="submit-mainSec">
          <span style={{ color: "#00B282", fontSize: "25px" }}>
            <BsCheckCircleFill />
          </span>
          <p style={{ fontSize: "14px", fontWeight: "700" }}>
            Candidate Added Successfully
          </p>
          <button
            className="addMore-btn"
            onClick={() => setIsCandidateAdded(false)}
          >
            Add More
          </button>
        </div>
      )}
    </>
  );
};

export default AddHotlistForm;
