import React, { useRef } from "react";
import "../../style/ManageUsers/manageAddUsers.css";
import { useState } from "react";
import { Button, Modal, message } from "antd";
import { Select } from 'antd';
import { addUser } from "../../services/mangeUserServices";

const ManageAddUsers = ({ manageUserData }: any) => {
  const [modalAddUser, setModalAddUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formErrors, setFormErrors]: any = useState({});

  const [addUserData, setAddUserData] = useState({
    fullName: "",
    email: "",
    password: "",
    role: "",
    phone: "",
  });
  const resetForm = () => {
    setAddUserData({
      fullName: "",
      email: "",
      password: "",
      role: "",
      phone: "",
    });
    setFormErrors({});
  }
  const showModalAddUser = () => {
    setModalAddUser(true);
  };

  const formRef: any = useRef(null);

  const { Option } = Select;

  function handleChange(e: any) {
    setAddUserData({ ...addUserData, role: e });
  }

  const handleUser = async (e: any) => {
    e.preventDefault();
    formRef.current.reportValidity();
    if (formRef.current.reportValidity()) {
      setIsLoading(true);
      await addUser(addUserData).then((response: any) => {
        if (response.success) {
          setIsLoading(false);
          manageUserData();
          setModalAddUser(false);
          resetForm();
        }
      }).catch((error: any) => {
        // console.log(error);
        if (error.status === 400) {
          message.error(error?.data?.errors[0]?.msg)
          // setFormErrors(error?.data?.errors)
          let errorObj = {}
          error?.data?.errors?.forEach((error: any) => {
            errorObj = {
              ...errorObj,
              [`${error.param}`]: error.msg
            }
          })
          setFormErrors(errorObj)
          // setFormErrors()
        }
        setIsLoading(false);
      })
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="main-add-user">
      <button onClick={showModalAddUser} className="manage-addUser-btn"> Add Users </button>
      <Modal
        maskStyle={{ backgroundColor: "#000000a3" }}
        className="modal-open-user"
        width={600}
        open={modalAddUser}
        onOk={() => setModalAddUser(false)}
        // onCancel={() => {setModalAddUser(false);resetForm();}}
        footer={null}
      >
        <form
          className="pop-user-modal"
          style={{ height: "fit-content" }}
          ref={formRef}
          onSubmit={handleUser}
        >
          <div className="user-header">
            <p>Add User</p>
            <div className="close-box" onClick={() => { setModalAddUser(false); resetForm(); }}>
              <span className="cross-btn">X</span>
            </div>
          </div>
          <div className="ass-user-details">
            <input
              type="text"
              value={addUserData.fullName}
              placeholder="User Name"
              onChange={(e: any) => {
                setAddUserData({ ...addUserData, fullName: e.target.value });
              }}
              required
            />
            {formErrors?.fullName && <span className="formValidateError">{formErrors?.fullName}</span>}
            <Select
              className="modal-select-manage"
              value={addUserData.role}
              style={{ width: '300px' }}
              onChange={handleChange}
            >
              <Option value="" disabled selected>User Type</Option>
              <Option value="0">Admin</Option>
              <Option value="1"> Recruiter </Option>
            </Select>
            {formErrors?.role && <span className="formValidateError">{formErrors?.role}</span>}
            <input
              type="email"
              value={addUserData.email}
              placeholder="Email"
              onChange={(e: any) => {
                setAddUserData({ ...addUserData, email: e.target.value });
              }}
              required
            />
            {formErrors?.email && <span className="formValidateError">{formErrors?.email}</span>}
            <input
              type="tel"
              value={addUserData.phone}
              placeholder="Phone"
              onChange={(e: any) => {
                setAddUserData({ ...addUserData, phone: e.target.value });
              }}
              required
            />
            {formErrors?.phone && <span className="formValidateError">{formErrors?.phone}</span>}
            <input
              type="password"
              value={addUserData.password}
              placeholder="Password"
              onChange={(e: any) => {
                setAddUserData({ ...addUserData, password: e.target.value });
              }}
              required
            />
            {formErrors?.password && <span className="formValidateError">{formErrors?.password}</span>}
          </div>
          <div className="user-add-click">
            <Button loading={isLoading} className="user-btn" htmlType="submit">
              Add
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ManageAddUsers;
