import { useEffect, useState } from "react";
import { IoMdTrash } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { hotlistTableAllDataDelete } from "../../services/HotlistTableData";
import {
  setBenchFilter,
  setFilterData,
  setRefresh,
} from "../../redux/candidateTableSlice";
import DeleteModal from "../popup/DeleteModal";
import reseticon from "../../assets/ResetIcon.png";
import { Select } from "antd";
import { Link, useLocation } from "react-router-dom";
import ExportCandidate from "./ExportCandidate";
import ColumnSetting from "../popup/ColumnSetting";
import { ReactComponent as ResetLogo } from "../../Image/Reset.svg";

const CandidateTableHead = ({
  setSearchAnyData,
  setCurrentPage,
  allTableData,
  searchAnyData,
  setColumnOrder,
  visibleColumns,
  allColumns,
}: any) => {
  const dispatch = useDispatch();
  const objData: any = useSelector(
    (state: any) => state?.candidateTable?.filterData
  );
  const refresh = useSelector((state: any) => state?.candidateTable?.refresh);
  const { candidateTable } = useSelector((state: any) => state);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const location: any = useLocation();

  const { user } = useSelector((state: any) => state.user);

  const deleteAllTableData = () => {
    setConfirmDelete(true);
    if (candidateTable?.id.length > 0) {
      hotlistTableAllDataDelete({ id: candidateTable?.id }).then(() => {
        dispatch(setRefresh());
        setConfirmDelete(false);
      });
    } else {
    }
  };


  useEffect(()=>{
    setSearchAnyData("")
  },[candidateTable?.benchFilter])


  const globalReset = () => {
    const objForFilter: any = {
      id: [],
      availability: [],
      experience: [],
      fullName: [],
      isExport: false,
      page: 1,
      perPage: 10,
      relocation: [],
      searchBy: "",
      searchField: "",
      sortField: "",
      sortType: "",
      specialBody: {
        benchSaleIds: [],
        domains: [],
      },
      state: [],
      title: [],
      visaType: [],
      createdAt: {
        startDate: "",
        endDate: "",
      },
      updatedAt: {
        startDate: "",
        endDate: "",
      },
    };

    setSearchAnyData("");
    setCurrentPage("1")
    let isFilterHasValue: any = false;

    Object.keys(objData)?.forEach((obj: any) => {
      if (Array?.isArray(objData[obj])) {
        if (objData[obj].length > 0) {
          isFilterHasValue = true;
        }
      } else if (
        objData?.specialBody?.domains?.length > 0 ||
        objData?.specialBody?.benchSaleIds?.length > 0
      ) {
        isFilterHasValue = true;
        dispatch(setBenchFilter([]));
      } else if (objData[obj]?.startDate?.length > 0){
        isFilterHasValue = true;
      }
    });

    if (isFilterHasValue) {
      dispatch(setFilterData(objForFilter));
    }
  };

  const isFilterReset = () => {
    let isFilterHasValue: any = false;
    Object.keys(objData)?.forEach((obj: any) => {
      if (Array?.isArray(objData[obj])) {
        if (objData[obj].length > 0) {
          isFilterHasValue = true;
        }
      } else if (
        objData?.specialBody?.domains?.length > 0 ||
        objData?.specialBody?.benchSaleIds?.length > 0
      ) {
        isFilterHasValue = true;
      } else if (objData[obj]?.startDate?.length > 0) {
        isFilterHasValue = true;
      }
    });
    return isFilterHasValue;
  };

  return (
    <>
      <div className="candidate-hotlist-table-input-and-back-but">
        <div>
          <CiSearch className="search-icon-in-candidate-hotlist" />{" "}
          <input
            autoComplete="on"
            type="text"
            className="candidate-hotlist-table-input"
            placeholder="Search User"
            value={searchAnyData}
            onChange={(e: any) => {
              setSearchAnyData(e.target.value);
              setCurrentPage(1);
              dispatch(
                setFilterData({ ...objData, searchBy: e.target.value, page: 1 })
              );
            }}
          />
        </div>

        <div
          className="candidate-hotlist-damin-and-nameSearch"
          style={{ width: "45%" }}
        >
          {location.pathname === "/hotlist-form" ? null : (
            <Select
              mode="tags"
              open={false}
              style={{ width: "100%" }}
              onChange={(e: any) => {
                dispatch(setBenchFilter(e));
                dispatch(
                  setFilterData({
                    ...candidateTable?.filterData,
                    specialBody: {
                      benchSaleIds:
                        candidateTable?.filterData?.specialBody
                          ?.domains?.[0] === e?.[0]
                          ? []
                          : candidateTable?.filterData?.specialBody
                              ?.benchSaleIds,
                      domains:
                        candidateTable?.filterData?.specialBody
                          ?.domains?.[0] === e?.[0]
                          ? candidateTable?.filterData?.specialBody?.domains
                          : [],
                        },
                      })
                      );
                     
                    }}
                searchValue={""}
              value={candidateTable?.benchFilter}
              tokenSeparators={[","]}
            />
          )}
        </div>

        <div className="candidate-hotlist-table-in-button-section">
          {location.pathname === "/hotlist-form" ? null : (
            <div
              className="candidate-hotlist-table-in-reset-filter"
              onClick={globalReset}
              // style={{
              //   cursor:
              //     isFilterReset() ||
              //     searchAnyData?.length > 0 ||
              //     candidateTable?.benchFilter?.length > 0
              //       ? "pointer"
              //       : "not-allowed",
              // }}
            >
              {/* <img alt="" src={reseticon} className="resetFilterIcon" />
              <span style={{ fontSize: 10 }}>
                <b>Reset</b>
              </span> */}
              <ResetLogo className="resetFilterIcon" />
            </div>
          )}
          {location.pathname === "/hotlist-form" ? null : (
            <div className="column-setting">
              <ColumnSetting
                setColumnOrder={setColumnOrder}
                visibleColumns={visibleColumns}
                allColumns={allColumns}
              />
            </div>
          )}

          {user?.role == "0" && <ExportCandidate allTableData={allTableData} />}

          {user?.role == "0" && (
            <>
              {location.pathname === "/hotlist-form" ? null : (
                <button
                  className={
                    candidateTable?.id.length > 0
                      ? "candidate-hotlist-table-delet-but-hover"
                      : "candidate-hotlist-table-delet-but"
                  }
                  onClick={() => {
                    candidateTable?.id.length > 0
                      ? setConfirmDelete(true)
                      : setConfirmDelete(false);
                  }}
                >
                  <IoMdTrash fontSize={16} />
                  <label
                    className={
                      candidateTable?.id.length > 0
                        ? "lableEnable"
                        : "lableDisable"
                    }
                    style={{ fontSize: 13 }}
                  >
                    Delete
                  </label>
                </button>
              )}
            </>
          )}

          {location.pathname === "/hotlist-form" ? null : (
            <Link to={"/hotlist-form"} className="addhotlist-link">
              <button className="candidate-hotlist-table-add-hotlist-but">
                Add Hotlist
              </button>
            </Link>
          )}
        </div>
      </div>

      <DeleteModal
        setConfirmDelete={setConfirmDelete}
        confirmDelete={confirmDelete}
        deleteAllTableData={deleteAllTableData}
      />
    </>
  );
};

export default CandidateTableHead;
