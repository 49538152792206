import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { DatePicker, Space } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { setFilterData } from "../../redux/candidateTableSlice";

const { RangePicker } = DatePicker;

const DateModel = ({ column, selectedData, setSelectedData, isFilterIcon, setIsFilterIcon }: any) => {
  const objData: any = useSelector(
    (state: any) => state?.candidateTable?.filterData
  );

  const [startDate, setStartDate]: any = useState(
    objData?.[column.id]?.startDate || ""
  );
  const [endtDate, setEndtDate]: any = useState(
    objData?.[column.id]?.endDate || ""
  );
  const dispatch: any = useDispatch();
  const dateFormat = "YYYY-MM-DD";

  const handleDateChange = (e: any, range: any) => {       
    setStartDate(range?.[0]);
    setEndtDate(range?.[1]);
  };

  const filterFormSubmitForDate = () => {
    if(startDate !== "" && endtDate !== "" ){
        dispatch(
            setFilterData({
              ...objData,
              [column?.id]: { startDate: startDate, endDate: endtDate },
            })
          );
    }
    setIsFilterIcon(!isFilterIcon);
  };


  const clearFilterFormDate = () =>{
    
    if(startDate !== "" && endtDate !== ""){
        dispatch(
            setFilterData({
              ...objData,
              [column?.id]: { startDate: "", endDate: "" },
            })
          );
    }
    setIsFilterIcon(!isFilterIcon);
    setStartDate("");
    setEndtDate("");
    
  }

  const disabledDate = (current:any) =>{
    return current && current > moment().endOf('day');
  }

  const today :any = moment().format(dateFormat)
  const prevMonth :any = moment().subtract(30, 'days').format(dateFormat)
  
  return (
    <>
      <div className="above-arrow"></div>
      <div className="box-of-checkbox" style={{width:"252px", height:"115px"}}>
        <div className="selection-div-cover">
          <div id="selection-div" className="selection-div custom-popover">
            <RangePicker
            className="range-picker-filter"
              onCalendarChange={handleDateChange}
              disabledDate={disabledDate}
              allowEmpty={[true, true]}
              defaultValue={
                startDate === ""
                ? [startDate, endtDate]
                : [dayjs(startDate), dayjs(endtDate)]
              }
              format={dateFormat}
              defaultPickerValue={[dayjs(prevMonth), dayjs(today)]}
            />
          </div>
        </div>
        <div className="footer-of-filter">
          <button
            type="button"
            onClick={filterFormSubmitForDate}
            className="submit-btn-for-filter"
          >
            Submit
          </button>
          <button
            type="button"
              onClick={clearFilterFormDate}
            className="clear-btn-for-filter"
          >
            Clear
          </button>
        </div>
      </div>
    </>
  );
};

export default DateModel;
