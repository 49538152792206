import { createSlice } from "@reduxjs/toolkit";

export interface manageUserState {
  userData: any;
}



const initialState: manageUserState = {
    userData:[],
};

export const manageUserSlice = createSlice({
  name: "ManageUser",
  initialState,
  reducers: {
    
  },
});

export const { } = manageUserSlice.actions;

export default manageUserSlice.reducer;