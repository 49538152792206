import { useEffect, useRef, useState } from "react";
import { FaFilter } from "react-icons/fa";
import "../../style/FilterBox.css";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { setFilterData } from "../../redux/candidateTableSlice";
import Http from "../../services/http";
import { async } from "q";
import DateModel from "./DateModel";
import { object } from "prop-types";

const FilterBox = ({ column, data, tbodyData, setCurrentPage }: any) => {
  const objData: any = useSelector(
    (state: any) => state?.candidateTable?.filterData
  );

  const [isFilterIcon, setIsFilterIcon]: any = useState(false);
  const [isSelectAll, setIsSelectAll]: any = useState(false);
  const [selectedData, setSelectedData]: any = useState([]);
  const [searchData, setSearchData]: any = useState([]);
  const [inputValueForFilter, setInputValueForFilter]: any = useState("");
  const filterRef: any = useRef();
  const dispatch: any = useDispatch();

  useEffect(() => {
    setSelectedData(objData[column?.id] || []);
  }, [objData, isFilterIcon]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (e: any) => {
    if (filterRef.current && !filterRef.current.contains(e.target)) {
      if (e.target.classList.value.substr(0, 10) === "ant-picker") {
        return;
      }
      setIsFilterIcon(false);
      setInputValueForFilter("");
      setIsSelectAll(false);
      if (objData[column?.id]?.length === 0) {
        setSelectedData([]);
      }
    }
  };

  const filteredData = () => {
    let arr: any = [];

    if (inputValueForFilter?.length !== 0) {
      searchData?.map((e: any) => {
        if (!arr.includes(e?.[column?.id])) {
          if (e?.[column?.id] === "" || e?.[column?.id] === null) {
            return;
          } else {
            arr.push(e?.[column?.id]);
          }
        }
      });
    } else {
      data?.map((e: any) => {
        if (!arr.includes(e?.[column.id])) {
          if (e?.[column?.id] === "" || e?.[column?.id] === null) {
            return;
          } else {
            arr.push(e?.[column.id]);
          }
        }
      });
    }
    return arr;
  };

  const handleSelectAll = () => {
    setIsSelectAll(!isSelectAll);
    if (!isSelectAll) {
      setSelectedData(
        filteredData()?.map((e: any) => {
          return e;
        })
      );
    } else {
      setSelectedData([]);
    }
  };

  const checkBoxEvent = (element: any, e: any) => {
    if (selectedData?.includes(e)) {
      setSelectedData(selectedData?.filter((ele: any) => e !== ele));
    } else {
      setSelectedData([...selectedData, e]);
    }
  };

  const filterFormSubmit = () => {
    dispatch(
      setFilterData({ ...objData, [column?.id]: selectedData, page: 1 })
    );
    setCurrentPage(1);
    setIsFilterIcon(false);
  };

  const clearFilterForm = () => {
    setSelectedData([]);
    if (objData?.[column.id]?.length > 0) {
      dispatch(setFilterData({ ...objData, [column?.id]: [], page: 1 }));
      setSelectedData([]);
      setIsFilterIcon(false);
    }
  };

  useEffect(() => {
    const body: any = {
      aceessor: column?.id,
      value: inputValueForFilter,
    };

    if (inputValueForFilter.length > 0) {
      const forFilter: any = setTimeout(async () => {
        const result: any = await Http.post(
          "candidate/get-data-by-accessor",
          body,
          true
        )
          .then((res: any) => {
            if (res?.data?.length === 0) {
              setSelectedData([]);
              // setSelectedData([...selectedData , selectedData.length = 0]);
            }
            if (res.success) {
              setSearchData(res?.data);
            }
          })
          .catch((err: any) => {
            // console.log(err);
          });
      }, 1000);

      return () => clearTimeout(forFilter);
    }
  }, [inputValueForFilter]);

  return (
    <>
      <span ref={filterRef} style={{ position: "relative", top: "2px" }} >
        {column?.id === "createdAt" || column?.id === "updatedAt" ? (
          <FaFilter
            style={{
              // cursor: tbodyData.length > 0 ? "pointer" : "not-allowed",
              cursor: "pointer",
              color:
                objData?.[column?.id]?.startDate !== "" ? "#000000" : "#959595",
            }}
            onClick={() => {
              setIsFilterIcon(!isFilterIcon);
            }}
          />
        ) : (
          <FaFilter
            style={{
              // cursor: tbodyData.length > 0 ? "pointer" : "not-allowed",
              cursor: "pointer",
              color: objData?.[column?.id]?.length > 0 ? "#000000" : "#959595",
            }}
            onClick={() => {
              setIsFilterIcon(!isFilterIcon);
            }}
          />
        )}

        {(isFilterIcon && tbodyData.length > 0 ) && (
          <>
            {column.id === "updatedAt" || column.id === "createdAt" ? (
              <DateModel
                column={column}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                setIsFilterIcon={setIsFilterIcon}
                isFilterIcon={isFilterIcon}
              />
            ) : (
              <>
                <div className="above-arrow"></div>
                <div className="box-of-checkbox">
                  <div className="pop-search-div custom-popover">
                    <BsSearch
                      style={{
                        position: "absolute",
                        left: "15px",
                        color: "#949494",
                        fontSize: "14px",
                      }}
                    />
                    <input
                      id="input_check"
                      value={inputValueForFilter}
                      onChange={(e: any) =>
                        setInputValueForFilter(e.target.value)
                      }
                      type="text"
                      placeholder="Search here"
                    />
                  </div>
                  <div className="selection-div-cover">
                    <div
                      id="selection-div"
                      className="selection-div custom-popover"
                    >
                      <div className="check-wrap">
                        {/* <label htmlFor="Delivery Charges" className="check-mark"> */}
                        <input
                          type="checkbox"
                          style={{ cursor: "pointer" }}
                          id="select_all"
                          className="check-in-label"
                          onChange={handleSelectAll}
                          checked={
                            selectedData?.length ===
                              filteredData()?.map((e: any) => {
                                return e;
                              }).length && selectedData?.length !== 0
                          }
                          // onKeyDown={(e: any) => handleKeyDown(e, index)}
                        />
                        <label
                          // onKeyDown={(e: any) => handleKeyDown(e, index)}
                          htmlFor="select_all"
                          style={{
                            color: "#0e4d92",
                            marginLeft: "10px",
                            fontSize: "13px",
                            fontWeight: "500",
                            cursor: "pointer",
                          }}
                        >
                          Select All
                        </label>
                        {/* <span className="black-checkbox"></span> */}
                        {/* </label> */}
                      </div>
                    </div>
                    <div className="selection-check">
                      <ul id="slection-ul">
                        {filteredData()?.map((e: any, index: any) => {
                          index += 1;
                          return (
                            <>
                              <li key={index}>
                                <div className="check-wrap">
                                  <label
                                    style={{ display: "flex" }}
                                    htmlFor="Delivery Charges"
                                    className="check-mark"
                                  >
                                    <input
                                      style={{ cursor: "pointer" }}
                                      id={`check${index}`}
                                      className="check-in-label"
                                      type="checkbox"
                                      checked={selectedData?.includes(e)}
                                      onChange={(ele: any) =>
                                        checkBoxEvent(ele, e)
                                      }
                                    />
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                      htmlFor={`check${index}`}
                                    >
                                      {e}
                                    </label>
                                  </label>
                                </div>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="footer-of-filter">
                    <button
                      type="button"
                      onClick={filterFormSubmit}
                      className="submit-btn-for-filter"
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      onClick={clearFilterForm}
                      className="clear-btn-for-filter"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </span>
    </>
  );
};

export default FilterBox;
