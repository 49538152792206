import { useRef, useState } from "react";
import logo from "../../src/assets/nav-logo.png";
import logoText from "../../src/assets/Wiseskulls_Text.png";
import "../style/login.css";
import { Button, message } from "antd";
import { userLogin } from "../services/loginServices";
import { useDispatch } from "react-redux";
import { setUserLogin } from "../redux/loginSlice";
const Login = () => {

  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  
  const [isLoading, setIsLoading] = useState(false);
  const [userError, setUserError] :any= useState([]);

  const dispatch = useDispatch();
  const formRef: any = useRef(null);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    formRef.current.reportValidity();
    if (formRef.current.reportValidity()) {

      setIsLoading(true);

      await userLogin(userData)
        .then((response: any) => {
          
          dispatch(setUserLogin(response.data));
          setIsLoading(false);
          setUserData({
            email: "",
            password: "",
          })

        })
        .catch((error: any) => {
          // console.log(error);
          setUserError(error?.data?.errors.map((errorMessage:any)=>errorMessage.param))
          message.error(error?.data?.errors[0]?.msg);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };


  return (
    <div className="login-container">
      <div className="login-box">
      </div>

        {/* <img src={logogrey} alt="wiseskulls logo" /> */}
      <div className="login-form-container">
        <img src={logo} alt="wiseskulls logo" />
        <img src={logoText} alt="wiseskulls" />
        <h1>Hotlist Management</h1>
        <form  className="form-box" ref={formRef} onSubmit={handleSubmit} noValidate>
          <div className="input-box">
          <input
            type="email"
            className={`input-field ${ !userError.includes('email') ?"" :"input-errors" }`}
            value={userData.email}
            onChange={(e: any) =>
              setUserData({ ...userData, email: e.target.value })
            }
            placeholder="Email"
            required
          />
          {/* {userError.length > 0 && userError.includes('email') &&  <span style={{color:"#fa0f23"}}>E-mail not found</span>} */}
          </div>
          <div className="input-box">
          <input
            type="password"
            className={` input-field ${ !userError.includes('password') ?"" :"input-errors" }`}
           value={userData.password}
            onChange={(e: any) =>
              setUserData({ ...userData, password: e.target.value })
            }
            placeholder="Password"
            required
          />
        {/* {userError.length > 0 && userError.includes('password') && <span style={{color:"#fa0f23"}}>Invalid Password</span>} */}
        </div>
          <div className="login-form-box">
            <Button
              loading={isLoading}
              className="login-btn"
              htmlType="submit"
                       >
              Login
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
