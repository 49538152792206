import { useState } from "react";
import AddHotlistForm from "../components/addHotlist/AddHotlistForm";
import "../style/AddHotlist/AddHotlist.css";
import AddBenchsalesPopUp from "../components/addHotlist/AddBenchsalesPopUp";
import { useLocation } from "react-router-dom";
import CandidateHotlistTable from "../components/candidateTable/CandidateHotlistTable";
import DropDownArrow from "../components/addHotlist/DropDownArrow";
import AddHotlistGuideLines from "../components/popup/AddHotlistGuideLines";

function AddHotlist() {
  const [isBenchSalesOpen, setIsBenchSalesOpen] = useState(false);
  const [isBenchSaleAdded, setIsBenchSaleAdded] = useState(false);
  const location = useLocation();




  return (
    <>
      <div className="addHotlist-mainSections">
        <div className="hotlist-form-and-head-handler">
          <div style={{
            display: "flex",
            alignItems: "center",
            gap: "60px",
          }}>
            <p className="add-hotlist-word">
              {location.state === null ? "Add Hotlist" : "Hotlist"}
            </p>
            <p style={{ fontWeight: "800" }}>
              {location.state === null ? "Select/Add Benchsales to add new hotlist. Scroll below to check the list of hotlist under particular benchsales." : ""}
            </p>
            {location.state === null ? <AddHotlistGuideLines /> : ""}

          </div>
          <div className="add-hotlist-form-handler">
            <AddHotlistForm
              isBenchSalesOpen={isBenchSalesOpen}
              setIsBenchSalesOpen={setIsBenchSalesOpen}
              isBenchSaleAdded={isBenchSaleAdded}
            />
          </div>
          {location.state === null ?
            <div className="drop-arrow" >
              <DropDownArrow />
            </div> : null}
        </div>
      </div>
      <AddBenchsalesPopUp
        isBenchSalesOpen={isBenchSalesOpen}
        setIsBenchSalesOpen={setIsBenchSalesOpen}
        isBenchSaleAdded={isBenchSaleAdded}
        setIsBenchSaleAdded={setIsBenchSaleAdded}
      />
      {location.state === null ? (
        <div className="form-table" style={{ marginBottom: "2%" }}>
          <h1 style={{ marginLeft: "40px" }}>Candidate in the List</h1>
          <CandidateHotlistTable />
        </div>
      ) : null}
    </>
  );
}

export default AddHotlist;
