import { createSlice } from "@reduxjs/toolkit";

export interface formState {
    bechSalesId: any;
    formRefresh:boolean
}

const initialState: formState = {
    bechSalesId: "",
    formRefresh: false
};

export const formSlice = createSlice({
    name: "form",
    initialState,
    reducers: {
        setBenchSalesId: (state, action) => {
            state.bechSalesId = action.payload;
        },
        refreshForm: (state) =>{
            state.formRefresh = !state.formRefresh
        }
    },
});

export const { setBenchSalesId,refreshForm } = formSlice.actions;

export default formSlice.reducer;
