/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, message } from "antd";
import Http from "../../services/http";
import "../../style/AddHotlist/AddBenchsalesPopUp.css";
import { BsCheckCircleFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";


const AddBenchsalesPopUp = ({
  isBenchSalesOpen,
  setIsBenchSalesOpen,
  isBenchSaleAdded,
  setIsBenchSaleAdded,
  isBenchsalesData,
  idForUpdate,
}: any) => {
  const location: any = useLocation();

  const [benchsalesData, setBenchsalesData]: any = useState({
    businessEmail: "",
    firstName: "",
    lastName: "",
    phone: "",
    linkedInId: "",
    hangoutId: "",
    whatsAppNumber: "",
    location: "",
  });

  useEffect(() => {
    setBenchsalesData({
      businessEmail: isBenchsalesData?.businessEmail,
      firstName: isBenchsalesData?.firstName,
      lastName: isBenchsalesData?.lastName,
      phone: isBenchsalesData?.phone,
      linkedInId: isBenchsalesData?.linkedInId,
      hangoutId: isBenchsalesData?.hangoutId,
      whatsAppNumber: isBenchsalesData?.whatsAppNumber,
      location: isBenchsalesData?.location,
    });
  }, [isBenchSalesOpen]);
  // const [formValidationErrors, setFormValidationErrors]: any = useState([]);
  const [isFormErrors, setIsFormErrors]: any = useState({});
  const [isformSubmitLoader, setIsformSubmitLoader]: any = useState(false);

  const benchSalesSubmit = async (e: any) => {
    setIsformSubmitLoader(true);
    e.preventDefault();
    const body: any = {
      firstName: benchsalesData?.firstName,
      lastName: benchsalesData?.lastName,
      businessEmail: benchsalesData?.businessEmail,
      hangoutId: benchsalesData?.hangoutId,
      phone: benchsalesData?.phone,
      linkedInId: benchsalesData?.linkedInId,
      whatsAppNumber: benchsalesData?.whatsAppNumber,
      location: benchsalesData?.location,
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const result: any = await Http.post("benchsales/addBenchSales", body, true)
      .then((response: any) => {
        if (response.success) {
          setIsformSubmitLoader(false)
          setIsBenchSaleAdded(true);
          setBenchsalesData("");
          // setFormValidationErrors([]);
          setIsFormErrors({})
        } else {
          setIsformSubmitLoader(false)
          setIsFormErrors({})
          setIsBenchSaleAdded(false);
          // setFormValidationErrors([]);
        }
      })
      .catch((error: any) => {
        const errorParam = error?.data?.errors?.map((err: any) => err?.param);
        // setFormValidationErrors(errorParam);
        setIsformSubmitLoader(false)
        if (error.status === 400) {
          message.error(error?.data?.errors[0]?.msg)

          let nulobj = {}
          error?.data?.errors?.forEach((error: any) => {
            nulobj = {
              ...nulobj,
              [`${error.param}`]: error.msg
            }

          });
          setIsFormErrors(nulobj);
        }
        else {
          setIsFormErrors({})

        }
      });
  };

  const benchSalesUpdate = async (e: any) => {
    setIsformSubmitLoader(true);
    e.preventDefault();
    const body: any = {
      id: idForUpdate,
      firstName: benchsalesData?.firstName,
      lastName: benchsalesData?.lastName,
      businessEmail: benchsalesData?.businessEmail,
      hangoutId: benchsalesData?.hangoutId,
      phone: benchsalesData?.phone,
      linkedInId: benchsalesData?.linkedInId,
      whatsAppNumber: benchsalesData?.whatsAppNumber,
      location: benchsalesData?.location,
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const result: any = await Http.put(
      "benchsales/update-benchsales",
      body,
      true
    ).then((response: any) => {
      if (response.success) {
        setIsBenchSalesOpen(false);
        setIsformSubmitLoader(false);
        setIsBenchSaleAdded(false);
        setBenchsalesData("");
        setBenchsalesData({
          businessEmail: "",
          firstName: "",
          lastName: "",
          phoneNo: "",
          linkedinId: "",
          hangoutId: "",
          whatsApp: "",
          location: "",
        });
      } else {
        setIsformSubmitLoader(false);
        setIsBenchSaleAdded(false);
      }
    }).catch((error: any) => {
      setIsformSubmitLoader(false);
      if (error.status === 400) {
        message.error(error?.data?.errors[0]?.msg)

        let nulobj = {}
        error?.data?.errors?.forEach((error: any) => {
          nulobj = {
            ...nulobj,
            [`${error.param}`]: error.msg
          }

        });
        setIsFormErrors(nulobj);
      }
      else {
        setIsFormErrors({})

      }
    });
  };



  return (
    <>
      <Modal
        title=""
        afterClose={() => setIsBenchSalesOpen(false)}
        className="add-Benchsales-popUp"
        open={isBenchSalesOpen}
        maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
        width={"1300px"}
        footer={null}
        onOk={() => setIsBenchSalesOpen(false)}
        onCancel={() => { setIsBenchSalesOpen(false); setIsBenchSaleAdded(false) }}
      >
        {!isBenchSaleAdded ? (
          <div className="bench-main-sec">
            <p className="salesDetails-para">Bench Sales Details</p>

            <form
              onSubmit={
                location?.pathname === "/hotlist-form"
                  ? benchSalesSubmit
                  : benchSalesUpdate
              }
            >
              <div className="benchSales-Input-section">
                <div className="benchSalesFormSection">
                  <input
                    type="email"
                    className="benchSales_all_input"
                    // style={{
                    //   border:
                    //     formValidationErrors?.includes("businessEmail") &&
                    //     "1px solid red",
                    // }}
                    name="businessEmail"
                    placeholder="Business Email"
                    value={benchsalesData?.businessEmail}
                    onChange={(e: any) =>
                      setBenchsalesData({
                        ...benchsalesData,
                        businessEmail: e.target.value,
                      })
                    }
                    required
                  />
                  {isFormErrors.businessEmail && <span className="formValidateError ">{isFormErrors.businessEmail}</span>}

                </div>
                <div className="benchSalesFormSection">
                  <input
                    type="text"
                    className="benchSales_all_input"
                    placeholder="First Name"
                    value={benchsalesData.firstName}
                    onChange={(e: any) =>
                      setBenchsalesData({
                        ...benchsalesData,
                        firstName: e.target.value,
                      })
                    }
                    required
                  />
                  {isFormErrors.firstName && <span className="formValidateError ">{isFormErrors.firstName}</span>}

                </div>
                <div className="benchSalesFormSection">
                  <input
                    type="text"
                    className="benchSales_all_input"
                    placeholder="Last Name"
                    value={benchsalesData.lastName}
                    onChange={(e: any) =>
                      setBenchsalesData({
                        ...benchsalesData,
                        lastName: e.target.value,
                      })
                    }
                  // required
                  />
                  {isFormErrors.lastName && <span className="formValidateError ">{isFormErrors.lastName}</span>}

                </div>
              </div>
              <div className="benchSales-Input-section">
                <div className="benchSalesFormSection">
                  <input
                    // style={{
                    //   border:
                    //     formValidationErrors?.includes("phone") &&
                    //     "1px solid red",
                    // }}
                    name="phone"
                    type="text"
                    className="benchSales_all_input"
                    placeholder="Phone No."
                    value={benchsalesData.phone}
                    onChange={(e: any) =>
                      setBenchsalesData({
                        ...benchsalesData,
                        phone: e.target.value,
                      })
                    }
                  // required
                  />
                  {isFormErrors.phone && <span className="formValidateError ">{isFormErrors.phone}</span>}

                </div>
                <div className="benchSalesFormSection">
                  <input
                    type="text"
                    className="benchSales_all_input"
                    placeholder="Linkedin ID"
                    value={benchsalesData.linkedInId}
                    onChange={(e: any) =>
                      setBenchsalesData({
                        ...benchsalesData,
                        linkedInId: e.target.value,
                      })
                    }
                  // required
                  />
                  {isFormErrors.linkedInId && <span className="formValidateError " >{isFormErrors.linkedInId}</span>}

                </div>
                <div className="benchSalesFormSection">
                  <input
                    type="email"
                    className="benchSales_all_input"
                    // style={{
                    //   border:
                    //     formValidationErrors?.includes("hangoutId") &&
                    //     "1px solid red",
                    // }}
                    name="hangoutId"
                    placeholder="Hangout ID"
                    value={benchsalesData.hangoutId}
                    onChange={(e: any) =>
                      setBenchsalesData({
                        ...benchsalesData,
                        hangoutId: e.target.value,
                      })
                    }

                  // required
                  />
                  {isFormErrors.hangoutId && <span className="formValidateError ">{isFormErrors.hangoutId}</span>}

                </div>
              </div>
              <div className="benchSales-Input-section">
                <div className="benchSalesFormSection">
                  <input
                    // style={{
                    //   border:
                    //     formValidationErrors?.includes("whatsAppNumber") &&
                    //     "1px solid red",
                    // }}
                    name="whatsAppNumber"
                    type="text"
                    className="benchSales_all_input"
                    placeholder="WhatsApp Number"
                    value={benchsalesData.whatsAppNumber}
                    onChange={(e: any) =>
                      setBenchsalesData({
                        ...benchsalesData,
                        whatsAppNumber: e.target.value,
                      })
                    }
                  // required
                  />
                  {isFormErrors.whatsAppNumber && <span className="formValidateError ">{isFormErrors.whatsAppNumber}</span>}
                </div>
                <div className="benchSalesFormSection">
                  <input
                    type="text"
                    className="benchSales_all_input"
                    placeholder="Location"
                    value={benchsalesData.location}
                    onChange={(e: any) =>
                      setBenchsalesData({
                        ...benchsalesData,
                        location: e.target.value,
                      })
                    }
                  // required
                  />
                  {isFormErrors.location && <span className="formValidateError ">{isFormErrors.location}</span>}

                </div>
              </div>
              <div className="add-btnSec">
                <Button htmlType="submit" loading={isformSubmitLoader}>
                  {/* {isformSubmitLoader ? <Spin indicator={antIcon} style={{ color: "#ffffff", margin: "12px" }} /> : ""} */}
                  {location?.pathname === "/hotlist-form" ? "Add" : "Update"}
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <div className="backTo-search">
            <span style={{ color: "#00B282", fontSize: "25px" }}>
              <BsCheckCircleFill />
            </span>

            <p style={{ fontSize: "14px", fontWeight: "700" }}>
              Benchsales Added Successfully
            </p>

            <button
              className="go-backSec"
              onClick={(e: any) => {
                e.preventDefault();
                setIsBenchSalesOpen(false);
                setIsBenchSaleAdded(false);
              }}
            >
              Go Back To Search{" "}
            </button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default AddBenchsalesPopUp;
