import { useEffect } from "react";
import "../../style/ManageUsers/columnSetting.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useState } from "react";
import { Modal } from "antd";
import { RxDragHandleDots2 } from "react-icons/rx";
import columnSetIcon from "../../Image/columnSet.png";
import {ReactComponent as ColumnLogo} from "../../Image/Column.svg"
import hotlistHeaderData from "../candidateTable/candidateColumns";
import { useLocation } from "react-router-dom";



// import { ReactComponent as columnIcon } from "../../Image/Column.svg"

const ColumnSetting = ({ setColumnOrder, allColumns }: any) => {
  const [selectedColumns, setSelectedColumns]: any = useState(allColumns);
  const [openColumnModal, setOpenColumnModal] = useState(false);
  const [finalColumns, setFinalColumns]: any = useState([]);
  const [hiddenColumns, setHiddenColumns]: any = useState([]);

  useEffect(() => {
    const candidateTableOrderingIndexs = JSON.parse(
      localStorage.getItem("candidateTableOrderingIndex") || "[]"
    );

    const candidateTableHiddenColumns = JSON.parse(
      localStorage.getItem("candidateTableHiddenColumns") || "[]"
    );

    if (candidateTableHiddenColumns?.length > 0)
      setHiddenColumns(candidateTableHiddenColumns);

    if (candidateTableOrderingIndexs?.length > 0) {
      const modifiedAllColumns = candidateTableOrderingIndexs?.map(
        (indexEle: any) => {
          const findIndex = allColumns?.findIndex(
            (ele: any) => ele.id == indexEle
          );
          return allColumns[findIndex];
        }
      );
      setSelectedColumns(modifiedAllColumns);
      setColumnOrder(candidateTableOrderingIndexs);
    }
  }, [openColumnModal]);

  const showModal = () => {
    setOpenColumnModal(true);
  };

  const handleColumnChange = (result: any) => {
    if (!result.destination) {
      return;
    }
    const list: any = Array.from(selectedColumns);
    const [removed] = list.splice(result.source.index, 1);
    list.splice(result.destination.index, 0, removed);

    setSelectedColumns(list);
    setFinalColumns(list.map((d: any) => d.id));
  };

  const handleColumnSubmit = () => {
    setColumnOrder(finalColumns);
    setOpenColumnModal(false);
    localStorage.setItem(
      "candidateTableOrderingIndex",
      JSON.stringify(finalColumns || [])
    );
    localStorage.setItem(
      "candidateTableHiddenColumns",
      JSON.stringify(hiddenColumns || [])
    );
  };

  const singleCheckBoxChange = (e: any) => {
    const { checked, id } = e.target;

    if (!checked) {
      setHiddenColumns([...hiddenColumns, id]);
    } else {
      const modifiedArr: any = hiddenColumns?.filter((ele: any) => ele != id);
      setHiddenColumns(modifiedArr);
    }
  };

  const cancleModal = () => {
    setHiddenColumns([]);
    setOpenColumnModal(false);
  };

  const resetColumns = () => {
    localStorage.removeItem("candidateTableHiddenColumns");
    const finalColumns = hotlistHeaderData?.map((ele: any) => ele.accessor);
    localStorage.setItem(
      "candidateTableOrderingIndex",
      JSON.stringify(finalColumns || [])
    );
    setFinalColumns(finalColumns);
    setColumnOrder(finalColumns);
    cancleModal();
  };
  // const location: any = useLocation();

  // if (location.pathname === "/hotlist-form") return <></>

  return (
    <div className="column-add-data-modal">
      {/* <img
        src={columnLogo}
        alt=""
        className="column-click-item"
        onClick={showModal}
      /> */}
      <ColumnLogo className="column-click-item"
        onClick={showModal} />

      <Modal
        className="main-column-modal"
        style={{ top: "8%" }}
        open={openColumnModal}
        width={400}
        onOk={cancleModal}
        onCancel={cancleModal}
        footer={null}
      >
        <div
          className="column-modal-all-details"
          style={{ height: "max-content" }}
        >
          <div className="column-first-item-setting">
            {/* <img src={columnSetIcon} className="columnSettingImg" alt="" /> */}
            <ColumnLogo className="columnSettingImgModal" />

            <p className="column-set">Column Settings</p>
          </div>
          <hr />

          <div className="column-second-details-section">
            <DragDropContext onDragEnd={handleColumnChange}>
              <Droppable droppableId={"1"}>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  // style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {selectedColumns?.map((e: any, index: any) => {
                      if (
                        e?.id === "id" ||
                        e?.id === "Selection" ||
                        !e?.Header
                      ) {
                        return;
                      }
                      return (
                        <Draggable
                          key={e?.id}
                          draggableId={e?.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              key={e?.id}
                              className="candidate-details"
                            >
                              <RxDragHandleDots2 style={{ fontSize: "20px" }} />
                              <input
                                className="click-check"
                                type="checkbox"
                                // {...e?.getToggleHiddenProps()} Changes By Akram
                                onChange={singleCheckBoxChange}
                                checked={
                                  hiddenColumns?.includes(e?.id) ? false : true
                                }
                                id={e?.id}
                              />
                              <label className="person-details" htmlFor={e?.id}>
                                {e?.Header}
                              </label>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <hr />
          <div className="last-two-click-btn">
            <button className="cancel-click-btn" onClick={resetColumns}>
              Reset
            </button>
            <button className="cancel-click-btn" onClick={cancleModal}>
              Cancel
            </button>
            <button className="apply-click-btn" onClick={handleColumnSubmit}>
              Apply
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ColumnSetting;
