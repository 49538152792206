
import ReactDOM from 'react-dom'
import './index.css'
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import AppWrapper from './App';


ReactDOM.render(

  <Provider store={store}>
    <AppWrapper />
  </Provider>,
  document.getElementById('root')

);

reportWebVitals();
