import { useState } from "react";
import "../style/header.css";
import wiseLogo from "../assets/nav-logo.png";
import wiseText from "../assets/Wiseskulls_Text.png";
import profile from "../assets/maleDummy.jpg";
import { HiBars3 } from "react-icons/hi2";
import ProfileDropDown from "../components/Header/ProfileDropDown";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const [hideUser, setHideUser] = useState(false);

  return (
    <>
      <div className="header-main-section">
        {location.pathname === "/dashboard" ? (
          <a href="/dashboard" onClick={() => setHideUser(false)}>
            <div className="header-logo-section">
              <img src={wiseLogo} style={{ height: "50px" }} />
              <img src={wiseText} style={{ height: "15px" }} />
            </div>
          </a>
        ) : (
          <Link to={"/dashboard"} onClick={() => setHideUser(false)}>
            <div className="header-logo-section">
              <img src={wiseLogo} style={{ height: "50px" }} />
              <img src={wiseText} style={{ height: "15px" }} />
            </div>
          </Link>
        )}

        <div className="header-profile-photo-section">
          <img
            src={profile}
            className="header-profile-photo"
            onClick={() => setHideUser(!hideUser)}
          />
          <HiBars3
            style={{ color: "white", fontSize: "25px", cursor: "pointer" }}
            onClick={() => setHideUser(!hideUser)}
            className="header-profile-photo-section-svg"
          />
        </div>
      </div>
      <ProfileDropDown hideUser={hideUser} setHideUser={setHideUser} />
    </>
  );
};

export default Header;
