import profile from "../../assets/maleDummy.jpg";
import { FaUserAlt } from "react-icons/fa";
import { TbPower } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { setUserLogout } from "../../redux/loginSlice";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import { IoIosTime, IoMdTimer } from "react-icons/io";

const ProfileDropDown = ({ hideUser, setHideUser }: any) => {
  const dispatch: any = useDispatch();
  const wrapref: any = useRef();
  const userData: any = useSelector((state: any) => state?.user?.user);

  const handleLogout = () => {
    dispatch(setUserLogout());
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (e: any) => {
    if (wrapref.current && !wrapref.current.contains(e.target)) {
      if (e.target.classList.value === "header-profile-photo" || e.target.classList.value === "header-profile-photo-section-svg") {
        return;
      }
      setHideUser(false);
    }
  };

  return (
    <>
      {hideUser ? (
        <div className="ProfileDropDown-main-section" ref={wrapref}>
          <div className="ProfileDropDown-first-section">
            <img src={profile} className="ProfileDropDown-profile-photo" />
            <div style={{ height: "50px", paddingTop: "4px" }}>
              <h3>
                {userData?.fullName?.charAt(0)?.toUpperCase() +
                  userData?.fullName?.slice(1)}
              </h3>
              <p>{userData?.role == 0 ? "Admin" : "Recruiter"}</p>
            </div>
          </div>
          {userData?.role == 0 ? (
            <div className="ProfileDropDown-user-section">
              <h4 style={{ textDecoration: "underline" }}>Manage</h4>
              <div className="ProfileDropDown-manage-user">
                <Link
                  to="/manageusers"
                  style={{ color: "black", textDecoration: "none" }}
                  onClick={() => setHideUser()}
                >
                  <FaUserAlt onClick={() => setHideUser()} />
                </Link>

                <Link
                  to="/manageusers"
                  style={{
                    cursor: "pointer",
                    color: "black",
                    textDecoration: "none",
                  }}
                  onClick={() => setHideUser()}
                >
                  User
                </Link>

              </div>
              {/* <div>
                <Link
                  to="/loginactivity"
                  style={{
                    cursor: "pointer",
                    color: "black",
                    textDecoration: "none",
                  }}
                  onClick={() => setHideUser()}
                >
                  <span className="ProfileDropDown-user-and-login-activity">
                    <IoIosTime fontSize={19}/>
                    Login Activity
                  </span>

                </Link>
              </div> */}
            </div>
          ) : (
            ""
          )}
          <div className="ProfileDropDown-LogOut-btn-section">
            <TbPower className="ProfileDropDown-LogOut-btn-icon" />
            <button
              className="ProfileDropDown-LogOut-btn"
              onClick={handleLogout}
            >
              Log Out
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ProfileDropDown;
