import "../../style//UserLoginLogOutTime.css";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CiSearch } from "react-icons/ci";
import { useSelector } from "react-redux";
import { Switch } from 'antd';
import UserActivityPagination from "./UserActivityPagination";
import UserActivityTable from "./UserActivityTable";
import { useState } from "react";
import { Button, Tooltip } from 'antd';



const UserLoginLogOutTime = () => {
  const userActivity: any = useSelector((state: any) => state?.user?.user);
  // console.log('userActivity>>>>>>', userActivity)
  const [open, setOpen] = useState(false)
  // console.log('open',open);

  const text = <>{open ? <span>Click For Login Time</span> : <span>Click For Count</span>}</>
  const buttonWidth = 70;




  dayjs.extend(customParseFormat);
  const { RangePicker } = DatePicker;
  const dateFormat = 'YYYY/MM/DD';
  return (
    <div className='main-div'>
      <div className='div-one'>
        <div>
          <CiSearch className="search-icon-in-user-activity" />{" "}
          <input type="text" className="search-user-activity-inp" placeholder="Search User Activity" />
        </div>
        <div className="switch-n-date">

          <div>
            <Space direction="vertical" size={12}>
              <RangePicker
                defaultValue={[dayjs('2015/01/01', dateFormat), dayjs('2015/01/01', dateFormat)]}
                format={dateFormat}
              />
            </Space>

          </div>
          <div className="switch-btn-in-user-activity">
            <Tooltip placement="top" title={text}>
              <Switch checked={open} onChange={(checked: any) => setOpen(checked)} />
            </Tooltip>
          </div>
        </div>


      </div>
      <div className='div-two '>
        <UserActivityTable open={open} />

      </div>
      <div className='div-three'>

        <UserActivityPagination />

      </div>

    </div>
  )
}

export default UserLoginLogOutTime