import { createSlice } from "@reduxjs/toolkit";

export interface candidateTableState {
  id: any;
  refresh: any;
  filterData: any;
  selectedDataLength: any;
  benchFilter: any;
}

const initialState: candidateTableState = {
  id: [],
  refresh: true,
  filterData: {
    id: [],
    availability: [],
    experience: [],
    fullName: [],
    isExport: false,
    page: 1,
    perPage: 10,
    relocation: [],
    searchBy: "",
    searchField: "",
    sortField: "",
    searchSpecialVal: "",
    searchSpecialField: "",
    specialBody:{
      benchSaleIds:[],
      domains:[]
    },
    sortType: "",
    state: [],
    title: [],
    visaType: [],
    createdAt:{
      startDate:"",
      endDate:""
    },
    updatedAt:{
      startDate:"",
      endDate:"",
    }
  },
  selectedDataLength: {},
  benchFilter: [],
};

export const candidateTableSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setDeleteId: (state, action) => {
      state.id = action.payload;
    },

    setRefresh: (state) => {
      state.refresh = !state.refresh;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
    setSelectedDataFun: (state, action) => {
      state.selectedDataLength = action.payload;
    },
    setBenchFilter: (state, action) => {
      state.benchFilter = action.payload;
    },
  },
});

export const {
  setDeleteId,
  setRefresh,
  setFilterData,
  setSelectedDataFun,
  setBenchFilter,
} = candidateTableSlice.actions;

export default candidateTableSlice.reducer;
