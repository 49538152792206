import { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FiEdit3 } from 'react-icons/fi';
import Http from '../../services/http';
import { useSelector } from 'react-redux';


const HTMLEditor = ({ html, setHtml, setIsEditorOn }: any) => {
    const [isLoading, setIsLoading] = useState(false)

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            ['clean'],
        ],
    };

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'link',
        'image',
    ];

    const saveSettings = (e: any) => {
        e.preventDefault();
        setIsLoading(true)
        const body: any = {
            settingName: "hotListGuideLines",
            content: html,
        }
        Http.post("admin/add-project-settings", body, true).then((response: any) => {
            if (response.success) {
                setIsEditorOn(false)
                setIsLoading(false)
            }
        }).catch((error: any) => {
            console.log("error", error)
            if(error.success){
                setIsLoading(false)
            }
        })

    }

    return (
        <>
            <ReactQuill
                value={html}
                onChange={(value) => setHtml(value)}
                placeholder="Enter HTML content..."
                modules={modules}
                formats={formats}
                className='CustomReactQuill'
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button type="primary" style={{
                    marginTop: "5px", background: "#00B282 0% 0% no-repeat padding-box", width: "20%",
                    height: "50px", fontSize: "18px"
                }}
                loading={isLoading}
                onClick={saveSettings}>Update</Button>
            </div>
        </>
    );
}

const AddHotlistGuideLines = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditorOn, setIsEditorOn] = useState(false);
    const [html, setHtml] = useState('');
    const select = useSelector((state: any) => state?.user?.user)


    useEffect(() => {
        if (!isModalOpen) return
        if (isEditorOn) return
        const body: any = {
            settingName: "hotListGuideLines",
        }
        Http.post("admin/get-project-settings", body, false).then((response: any) => {
            if (response.success) {
                setHtml(response.data.content)
            }
        }).catch((error: any) => {
            console.log("naushaderror", error)
        })
    }, [isEditorOn, isModalOpen])

    return (
        <div>
            <span className="new-guidelines" onClick={() => setIsModalOpen(true)}>
                <p className="i-symbol">i</p>
                <i className="guideLinesWord">Guidelines</i>
            </span>
            <Modal
                open={isModalOpen}
                onOk={() => setIsModalOpen(false)}
                onCancel={() => { setIsModalOpen(false); setIsEditorOn(false) }}
                footer={null}
                width={990}
                wrapClassName="addHotlist-guideLine-modal"
            >
                <div>
                    {select?.role === "0" &&
                        <div style={{
                            display: "flex",
                            justifyContent: "end",
                            margin: "0 1rem"
                        }}>
                            <FiEdit3
                                onClick={() => setIsEditorOn(!isEditorOn)}
                                style={{
                                    fontSize: "24px",
                                    color: "#A4A5A5",
                                    cursor: "pointer",
                                }}
                            />
                        </div>}
                    {
                        isEditorOn ?
                            <HTMLEditor html={html} setHtml={setHtml} isEditorOn={isEditorOn} setIsEditorOn={setIsEditorOn} />
                            : <div className='guideLinesSettingsOut' dangerouslySetInnerHTML={{ __html: html }}></div>
                    }
                </div>
            </Modal>
        </div>
    );
}

export default AddHotlistGuideLines
